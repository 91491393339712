import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Observable } from "rxjs";

import { AppConfig } from '../app.config';

import { AuthService } from './auth.service';


import { EventBusService } from '../../framework/eventbus.service';
import { EmailValidator, PasswordValidator } from '../../framework/form.validator';
import { ProfileService } from '../profile/profile.service';
import { PlaceHolderFloat } from '../../framework/form/placeholder.directive';
import { ErrorInfoComponent } from '../../framework/form/errorInfo.directive';
import { UtilService } from '../../framework/utils/util.service';

import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from '../../framework/i18n';
import { SharedService } from '../shared/services/shared.service';

import * as ACTIONS_INTERFACE from '../actions';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { LatencyChecker } from '../../framework/latency-checker.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-loader.service';
import { GoogleApiService } from 'app/shared/services/google-api.service';
import { environment } from 'environments/environment';
import { AppConstants } from 'app/app.constants';
import { PreAuthService } from 'app/pre-auth/pre-auth.service';
import { v4 as uuidv4 } from 'uuid';
import { MsalService } from '@azure/msal-angular';

// import * as $ from "jquery";
// import * as jQuery from "jquery";

declare var $: any;
declare var window: any;
declare var jQuery: any;
declare var document: any;

interface User {
  password: string;
}

@Component({
  selector: 'login',
  providers: [AuthService, ProfileService, WorkflowService, PlaceHolderFloat, ErrorInfoComponent],
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  errorMessage: string = '';
  invalidLoginAttempts: number;
  isStatus409: string = '';
  isStatus500: string = '';
  isError: boolean = false;
  firstname: string;
  lastname: string;
  middlename: string;
  token: string;
  app_id: string;
  email: string;
  userid: string;
  user: User;
  isEmailError: boolean = false;
  isEmailErrorInfo: boolean = false;
  emailErrorMessage: string = '';
  isPwdError: boolean = false;
  isPwdErrorInfo: boolean = false;
  pwdErrorMessage: string = '';
  isEmailValid: boolean = false;
  isPwdValid: boolean = false;
  userBandWidth: number;
  minThreshold: number;
  trackObj: Object;
  companyName: any;
  secretQuestions: any;
  secretQuestion: string;
  secretAnswer: string;
  isSecretQuestionValid: boolean = false;
  isSecretAnswerValid: boolean = false;
  nextUrl: string;
  isInviteConsentType: boolean = false;
  isInviteEiaf: boolean = false;
  isInviteRightId: boolean = false;
  isInviteMI: boolean = false;
  isStandaloneWotc: boolean = false;
  isInviteAssessment: boolean = false;
  isInviteNotification: boolean = false;
  isIE11: boolean;
  isInviteDQMI: boolean = false;
  errorMessageModal: string;
  isAuthyLoggedIn: boolean = false;
  static NAME_MASKING_PREFERNCE_VALUE = '1458';
  static NAME_STEP_INDEX = 0;

  constructor(private _service: AuthService,
    private _router: Router,
    private _workflow: WorkflowService,
    private localStorage: LocalStorageService,
    private latencyChecker: LatencyChecker,
    private eventBus: EventBusService,
    private _profileService: ProfileService,
    private _placeholder: PlaceHolderFloat,
    private _errorInfo: ErrorInfoComponent,
    private _translate: TranslateService,
    private shareService: SharedService,
    private _preAuthService: PreAuthService,
    @Inject(DOCUMENT) private document: any,
    private _shareService: SharedService,
    private deviceService: DeviceDetectorService,
    private _eventBus: EventBusService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private googleApiService: GoogleApiService,
    @Inject(MsalService) private authyService: MsalService,
  ) {
    this.minThreshold = AppConfig.getMinThreshold();
    this.eventBus.saveexitAnnounced$.subscribe(item => {
      this._next('/dashboard');
    });
  }

  ngOnInit() {
    this.isInviteConsentType = this._shareService.checkInviteIsConsentType();
    this.isInviteEiaf = this._shareService.checkInviteIsEIAF();
    this.isInviteRightId = this._shareService.checkInviteIsRightId();
    this.isInviteMI = this._shareService.checkInviteIsMI();
    this.isStandaloneWotc = this._shareService.checkInviteIsStandaloneWotc();
    this.isInviteAssessment = this._shareService.checkInviteIsAssessment();
    this.isInviteDQMI = this._shareService.checkInviteIsDQMI();
    this.isInviteNotification = this._shareService.checkInviteIsNotification();
    this.isAuthyLoggedIn = this.localStorage.getItem("isAuthyLoggedIn");

    this.firstname = "";
    this.companyName = "";
    this.middlename = "";
    this.email = "";
    this.isStatus409 = this.localStorage.getItem('Status_409');
    this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if (this.localStorage.getItem('Recruiter_Name') !== null && this.localStorage.getItem('Recruiter_Name') !== undefined) {
      // this.companyName = " " + this.localStorage.getItem('Recruiter_Name');
      this.companyName = " " + this.localStorage.getItem('Recruiter_Name');
    }

    if (this.localStorage.getItem('first_name') !== null && this.localStorage.getItem('first_name') !== undefined)
      this.firstname = " " + this.localStorage.getItem('first_name');

    if (this.localStorage.getItem('applicant_email') !== null) {
      this.email = this.localStorage.getItem('applicant_email');

      if (this.isStatus409 === null) {
        $('#signintext').addClass("show");
        $('#signintextsecond').addClass("hide");
        $('#signintextsmall').addClass("show");
        $('#signintextsecondsmall').addClass("hide");
        $('#signintextstatus400').addClass("hide");
        $('#signintextstatus400small').addClass("hide");
        $('#signintextthirdsmall').addClass("hide");
        $('#signintextthird').addClass("hide");
      }

      if (this.isStatus409 != null) {
        $('#signintext').addClass("hide");
        $('#signintextsecond').addClass("hide");
        $('#signintextsmall').addClass("hide");
        $('#signintextsecondsmall').addClass("hide");
        $('#signintextstatus400').addClass("show");
        $('#signintextstatus400small').addClass("show");
        $('#signintextthirdsmall').addClass("hide");
        $('#signintextthird').addClass("hide");
      }

      if ((this.localStorage.getItem('applicant_email') === undefined && this.isStatus409 === undefined) || this.companyName === "" || this.companyName === undefined) {
        $('#signintext').addClass("hide");
        $('#signintextthird').addClass("show");
        $('#signintextthirdsmall').addClass("show");
        $('#signintextsecond').addClass("hide");
        $('#signintextsmall').addClass("hide");
        $('#signintextsecondsmall').addClass("hide");
        $('#signintextstatus400').addClass("hide");
        $('#signintextstatus400small').addClass("hide");
      }
    }

    this._placeholder.placeholderFloatFunc();

    this.user = {
      password: ''
    };
    this._errorInfo.errorInfo();
    this.secretQuestions = ProfileService.getSecurityQuestions();

    if (this._shareService.showLanguageSelectorOnLogin()) {
      this._eventBus.languageListInInviteReceivedAnnounced({
        availableLangList: this.localStorage.getItem('availableLanguageList')
      });
    } else {
      this._eventBus.languageListInInviteReceivedAnnounced({
        hideLanguageSelectorInLoginPage: true
      });
    }
  }

  ngAfterViewInit() {
    this.isInviteEiaf = this._shareService.checkInviteIsEIAF();
    this.isInviteConsentType = this._shareService.checkInviteIsConsentType();
    this.isStandaloneWotc = this._shareService.checkInviteIsStandaloneWotc();
    setTimeout(() => {
      let emailVal = $('#loginInputEmail').val();

      if (emailVal !== '') {
        this.isEmailValid = true;
        this.isPwdValid = true;
      }
    }, 200);

    // We need to check the bandwidth only when the users lands in this page
    // for the first time and not on other scenarios, as we are redirecting to login page
    // from multiple pages, using window.sessionStorage as our local storage gets cleared
    // once we logout from the application.
    if (AppConfig.isFeatureAvailable('app-features', 'network-latency')) {
      if (!window.sessionStorage.getItem('isDldBandWidthChecked'))
        this.checkLatency();
    }
    if (!!this.isAuthyLoggedIn) {
      this.loginSuccess(null);
    }    
  }

  ngOnDestroy() { }

  /**
   * To identify the network bandwidth, and
   * notify the users if the available bandwidth is lesser than minimum threshold
  */
  checkLatency() {
    this.latencyChecker.getDownloadBandWidth().subscribe(dldBandwidth => {
      this.userBandWidth = dldBandwidth['speedMbps'];
      if (dldBandwidth['speedMbps'] < this.minThreshold) {
        $('#threshHoldAlertModal').modal('show');
        setTimeout(function () { $('#threshHoldAlertModal').modal('hide'); }, AppConfig.APP_CONSTANTS.threshHoldMsgTimeOut);
      };
    });

    window.sessionStorage.setItem('isDldBandWidthChecked', 'true');
  }

  closeTModal() {
    $('#threshHoldAlertModal').modal('hide');
  }

  postLogin(value: Object) {
    let that = this;
    $('#plsWaitOnIAgreeModal').modal('show');

    this._service.postLogin(value).subscribe(response => {
      if (response) {
         if (response['error']) {
          $('#plsWaitOnIAgreeModal').modal('hide');
          this.handleLoginError(response['error']);
        }
        else if (!response['status']) {
          // $('#plsWaitOnIAgreeModal').modal('hide');
          this.loginSuccess(response);
        } else {
           // JUST CLOSE MODAL AND DO NOTHING WHEN STATUS 410
           $('#plsWaitOnIAgreeModal').modal('hide');
          if (response['status'] !== 410) { 
            this.handleLoginError(this.getErrorMessage(response));
          }
        }
        
      }      
    },
      error => {
        this.handleLoginError(error);
      });
  }

  getErrorMessage(error: any) {
    let errorMessage: string;

    switch (error.status) {
      case 400:
      case 401:
      case 405:
      case 403:
        //Bad request, Invalid Credentials - login invalid
        errorMessage = error['error'] || 'Invalid Login';
        break;

      case 404:
        break;
      case 423:
        //errorMessage = error['error']['error'];
        errorMessage = 'ACCOUNT_LOCKED';
        break;
    }
    return errorMessage;
  }

  handleLoginError(error) {
    $('#plsWaitOnIAgreeModal').modal('hide');
    this.trackObj = {
      stage_name: 'login',
      action_value: 'login failed'
    };

    window.track_event('invalid password attempt', this.trackObj, true, true);

    this.errorMessage = error;
    this.isError = true;

    if (!!this.errorMessage && this.errorMessage.startsWith('Your account is locked')) {
      this.errorMessage = 'ACCOUNT_LOCKED';
    }
    
    // check for invalid login error messages (translation)
    if(this.errorMessage.startsWith("INVALID_LOGIN#")){
      let index = this.errorMessage.indexOf("#");
      this.invalidLoginAttempts = parseInt(this.errorMessage.substring(index + 1));
      this.errorMessageModal = this.errorMessage.substring(0, index) + '_NEW';;
      //this.errorMessage = this._translate.translate('INVALID_LOGIN');
      setTimeout("$('#invalidCredentials').modal('show');",2000);
      setTimeout("$('#invalidCredentials').modal('hide');",7000);
      this.errorMessage = this._translate.translate('INVALID_USERNAME_PASSWORD');
    } else if (this.errorMessage === "Invalid Username / Password.") {
      this.errorMessage = this._translate.translate('INVALID_USERNAME_PASSWORD');
    } else if(this.errorMessage === "ACCOUNT_LOCKED"){
      this.errorMessageModal = this.errorMessage ;
      this.errorMessage = this._translate.translate('INVALID_USERNAME_PASSWORD'); // not sure if discarding exsiting constant support is an option now, so retaining it
      setTimeout("$('#invalidCredentials').modal('show');",2000);
    } else if(this.errorMessage === "INVITE_EXPIRED"){
      // DO NOTHING
      this.errorMessage = '';
    } else {
      this.errorMessage = this._translate.translate('INVALID_LOGIN');
    }
  }

  login(value: Object): void {
    let that = this;
    $('#plsWaitOnIAgreeModal').modal('show');

    this._service.postLogin(value).subscribe(response => {
      if (response) {
        $('#plsWaitOnIAgreeModal').modal('hide');
        this.loginSuccess(response);
      }      
    },
      error => {
        $('#plsWaitOnIAgreeModal').modal('hide');
        this.trackObj = {
          stage_name: 'login',
          action_value: 'login failed'
        };

        window.track_event('invalid password attempt', this.trackObj, true, true);

        this.errorMessage = error;
        this.isError = true;

        if (!!this.errorMessage && this.errorMessage.startsWith('Your account is locked')) {
          this.errorMessage = 'ACCOUNT_LOCKED';
        }

        // check for invalid login error messages (translation)
        if(this.errorMessage.startsWith("INVALID_LOGIN#")){
          let index = this.errorMessage.indexOf("#");
          this.invalidLoginAttempts = parseInt(this.errorMessage.substring(index + 1));
          this.errorMessageModal = this.errorMessage.substring(0, index) + '_NEW';;
          //this.errorMessage = this._translate.translate('INVALID_LOGIN');
          setTimeout("$('#invalidCredentials').modal('show');",2000);
          setTimeout("$('#invalidCredentials').modal('hide');",7000);
          this.errorMessage = this._translate.translate('INVALID_USERNAME_PASSWORD');
        } else if (this.errorMessage === "Invalid Username / Password.") {
          this.errorMessage = this._translate.translate('INVALID_USERNAME_PASSWORD');
        } else if(this.errorMessage === "ACCOUNT_LOCKED"){
          this.errorMessageModal = this.errorMessage ;
          this.errorMessage = this._translate.translate('INVALID_USERNAME_PASSWORD'); // not sure if discarding exsiting constant support is an option now, so retaining it
          setTimeout("$('#invalidCredentials').modal('show');",2000);
        } else {
          this.errorMessage = this._translate.translate('INVALID_LOGIN');
        }

      });
  }

  async processResponse(inviteResponse: any): Promise<void> {

    const placeSearchAPi = inviteResponse['account']['place_search_api'];
    this.localStorage.setItem('place_search_api', placeSearchAPi);
    if (this.shareService.isGooglePlaceApiEnabled()) {
      // add google api js into script tag for the specific language
      await this.googleApiService.load(inviteResponse['language']);
    }

    if (!!inviteResponse['applicant']) {
      this.setApplicantDataInLocalStorage(inviteResponse['applicant']);
    }

    if (!!inviteResponse['account']) {
      this.localStorage.setItem('SMS_OPTOUT_ENABLED', inviteResponse['account']['SMS_OPTOUT_ENABLED'])
    }

    if (inviteResponse !== undefined && inviteResponse['account'] !== undefined &&
      inviteResponse['account']['is_ea_rightId']) {
      this.localStorage.setItem('is_ea_rightId', inviteResponse['account']['is_ea_rightId']);
    }

    if (!!inviteResponse && !!inviteResponse['account'] && !!inviteResponse['account']['support_chat_experience']) {
      this.localStorage.setItem('support_chat_experience', inviteResponse['account']['support_chat_experience']);
    }


    let ridStatus = undefined;
    if (!!inviteResponse['account'] && !!inviteResponse['account']['rid_status']) {
      ridStatus = inviteResponse['account']['rid_status'];
    }
    let importDataDLCaptureStatus = undefined;
    if (!!inviteResponse['account'] && !!inviteResponse['account']['import_data_status']) {
      importDataDLCaptureStatus = inviteResponse['account']['import_data_status'];
    }
    let importDataDLCaptureResult = undefined;
    if (!!inviteResponse['account'] && !!inviteResponse['account']['import_data_result']) {
      importDataDLCaptureResult = inviteResponse['account']['import_data_result'];
    }

    let isDLCapturePreFillEnabled = undefined;
    if (inviteResponse['account'] && inviteResponse['account']['is_capture_dl_prefill']) {
      isDLCapturePreFillEnabled = inviteResponse['account']['is_capture_dl_prefill'];
      this.localStorage.setItem('is_capture_dl_prefill', inviteResponse['account']['is_capture_dl_prefill']);
    }
    if (!!inviteResponse['disable_tos']) {
      this.localStorage.setItem('disable_tos', inviteResponse['disable_tos']);
    }
    if (inviteResponse != null && inviteResponse['account'] != undefined && inviteResponse['account']['config'] != undefined &&
      inviteResponse['account']['config']['preferences'] != undefined &&
      inviteResponse['account']['config']['preferences'].length > 0) {
      let preferenceList = inviteResponse['account']['config']['preferences'];
      for (var preference of preferenceList) {
        if (preference['name'] == 'WEBSDK_VENDOR' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          this.localStorage.setItem('websdk_vendor', preference['value']);
          continue;
        }
        if (preference['name'] == 'YOTI_TIMEOUT_IN_MINS' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          this.localStorage.setItem('yoti_timeout_in_mins', preference['value']);
          continue;
        }
        if (preference['name'] == 'DISABLE_YOTI_DIGITAL_WALLET_EXP' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          this.localStorage.setItem('disable_yoti_digital_wallet_exp', preference['value']);
          continue;
        }
      }
    }

    const env = environment;
     
    setTimeout(() => {
      this.localStorage.setItem("chat_exp", "SFC");
    }, 2000);

    let webSdkVendor = this.localStorage.getItem('websdk_vendor');
    let isYoti = (!!webSdkVendor && webSdkVendor === 'YOTI');
    this.localStorage.setItem("is_yoti", isYoti);
    console.log("LoginComponent webSdkVendor and isYoti----", webSdkVendor, isYoti);
    let subType = inviteResponse['subType'];
    this.localStorage.setItem('subType', subType);
    this.localStorage.setItem('rid_status', ridStatus);
    this.localStorage.setItem('import_data_status', importDataDLCaptureStatus);
    this.localStorage.setItem('import_data_result', importDataDLCaptureResult);
    if (!!inviteResponse["package"]) {
      this.localStorage.setItem('package_name', inviteResponse["package"]['name']);
      if (!!inviteResponse["package"]['distinct_components']) {
        this.localStorage.setItem('package_components', inviteResponse["package"]['distinct_components']);
      }
      if (!!inviteResponse["package"]['scheme']) {
        this.localStorage.setItem('scheme', inviteResponse["package"]["scheme"]);
      }
    }

    this.document.getElementById('brand').innerHTML = inviteResponse['custom_styles'];
    let inviteKey = inviteResponse['key'];
    let inviteUrl = this.localStorage.getItem('invite_url');
    this.localStorage.setItem('invite_key', inviteKey);
    this.isInviteRightId = this.shareService.checkInviteIsRightId();

    if (this.isInviteRightId && subType !== AppConfig.RIGHT_ID_ROUTER && !!ridStatus && (ridStatus === 'OPTED_OUT' || ridStatus === 'OPTED_OUT_DEVICE' || ridStatus === 'FRAUD_DETECTED' )) {
      console.log("EARightId Invite routing to thankyou page.");
      $('#plsWaitOnIAgreeModal').modal('hide');
      this._router.navigate(['/profile/thankyou']);
      return;
    }

    // 8214 / 8514 - Display QR Code, regardless of RightID, if preference is set.
    const isCaptureDLPrefill = this.localStorage.getItem('is_capture_dl_prefill') === 'Y';


    if ((this.isInviteRightId && (!subType || ((subType === AppConfig.RIGHT_ID_ROUTER || subType === AppConstants.INVITE_SUB_TYPE_KYP) && ridStatus === 'PENDING'))) ||
         (isCaptureDLPrefill && importDataDLCaptureStatus === 'PENDING')) {
      if (!isYoti && this.deviceService.isDesktop()) {
        this.errorMessage = <any>Error;
        this.errorMessage['errorMessage'] = 'UNSUPPORTED_DEVICE';
        this.shareService.logFlow("login", "unsupported-device", this.shareService.getDeviceDetails());
        $('#plsWaitOnIAgreeModal').modal('hide');
        this._shareService.redirectToUnsupportedDevice(this._service,this._router);
        return;
      }

      this.trackObj = {
        stage_name: 'login',
        action_value: 'save'
      };

      window.track_event('rightid invite, load js', this.trackObj, true, true);
      
      //Adding IDMetricsCaptureFramework.js to head tag if rightID ONLY or RID stages not completed
      let head = this.document.getElementsByTagName('head')[0];
      let script = document.createElement('script');
      script.src = '../../assets/js/IDMetricsCaptureFramework.js';
      head.appendChild(script);
      if (isYoti) {
        let yotiScript = document.createElement('script');
        yotiScript.src = 'https://www.yoti.com/share/client/';
        head.appendChild(yotiScript);
      }
    } else {
      //Adding modernizr-custom.js to head tag if invite is not RIGHT or RID stages are completed
      let head = this.document.getElementsByTagName('head')[0];
      let script = document.createElement('script');
      script.src = '../../assets/js/modernizr-custom.js';
      head.appendChild(script);
    }

    this.localStorage.setItem('key', inviteResponse['key']);

    // Check Invite Type is EIAF
    this.localStorage.setItem('inviteType', inviteResponse['type']);

    /*
     * Language setting - Browser takes precedence. Moved the code to shared service
     * for code reuse
    */
    await this.shareService.setLanguage(inviteResponse, this.eventBus);

    if (inviteResponse !== undefined && inviteResponse['account'] !== undefined) {
      if (inviteResponse['account']['is_report_on']) {
        this.localStorage.setItem('is_report_on', inviteResponse['account']['is_report_on']);
      }
      if (inviteResponse['account']['pa_dot_direct_preference']) {
        this.localStorage.setItem('pa_dot_direct_preference', inviteResponse['account']['pa_dot_direct_preference']);
      }
      if (inviteResponse['account']['pa_dot_drug_order_preference']) {
        this.localStorage.setItem('pa_dot_drug_order_preference', inviteResponse['account']['pa_dot_drug_order_preference']);
      }
      if (inviteResponse['account']['pa_dot_limit_employment_verification']) {
        this.localStorage.setItem('pa_dot_limit_employment_verification', inviteResponse['account']['pa_dot_limit_employment_verification']);
      }
      if (inviteResponse['account']['reason_for_leaving_mandatory']) {
        this.localStorage.setItem('reason_for_leaving_mandatory', inviteResponse['account']['reason_for_leaving_mandatory']);
      }
      if (inviteResponse['account']['ea_account_bg_report_completion_eta']) {
        this.localStorage.setItem('ea_account_bg_report_completion_eta', inviteResponse['account']['ea_account_bg_report_completion_eta']);
      }

      if (inviteResponse['account']['pa_lock_pre_population_for_custom_form_fields']) {
        this.localStorage.setItem('pa_lock_pre_population_for_custom_form_fields', inviteResponse['account']['pa_lock_pre_population_for_custom_form_fields']);
      }

      if (inviteResponse['account']['is_candidate_age_based_scope']) {
        this.localStorage.setItem('is_candidate_age_based_scope', inviteResponse['account']['is_candidate_age_based_scope']);
      }

      if (inviteResponse['account']['country_of_residence_pref_value']) {
        this.localStorage.setItem('country_of_residence_pref_value', inviteResponse['account']['country_of_residence_pref_value']);
      }
      if (inviteResponse['account']['pa_lock_down_name']) {
        this.localStorage.setItem('pa_lock_down_name', inviteResponse['account']['pa_lock_down_name']);
      }
      if (inviteResponse['account']['pa_do_not_override_name']) {
        this.localStorage.setItem('pa_do_not_override_name', inviteResponse['account']['pa_do_not_override_name']);
      }
      if (inviteResponse['account']['user_alert_on_error']) {
        this.localStorage.setItem('user_alert_on_error', inviteResponse['account']['user_alert_on_error']);
      }

      if (inviteResponse['account']['use_font_based_signature']) {
        this.localStorage.setItem('use_font_based_signature', inviteResponse['account']['use_font_based_signature']);
      }
      if (inviteResponse['account']['SIGNATURE_FONT']) {
        this.localStorage.setItem('SIGNATURE_FONT', inviteResponse['account']['SIGNATURE_FONT']);
      }

      if (inviteResponse['account']['is_salary_enabled']) {
        this.localStorage.setItem('is_salary_enabled', inviteResponse['account']['is_salary_enabled']);
      }
      
      if (inviteResponse['account']['enhanced_dashboard']) {
        this.localStorage.setItem('enhanced_dashboard', inviteResponse['account']['enhanced_dashboard']);
      }
      if (inviteResponse['account']['credit_card_pay_enabled']) {
        this.localStorage.setItem('credit_card_pay_enabled', inviteResponse['account']['credit_card_pay_enabled']);
      }
      if (inviteResponse['account']['payment_partner_pref_value']) {
        this.localStorage.setItem('payment_partner_pref_value', inviteResponse['account']['payment_partner_pref_value']);
      }
      
      if (!!inviteResponse['account']['azure_cognitive_search_enabled'] && inviteResponse['account']['azure_cognitive_search_enabled']) {
        this.localStorage.setItem('azure_cognitive_search_enabled', inviteResponse['account']['azure_cognitive_search_enabled']);
      }
      
      if (!!inviteResponse['account']['lookup_service_url'] && inviteResponse['account']['lookup_service_url']) {
        this.localStorage.setItem('lookup_service_url', inviteResponse['account']['lookup_service_url']);
      }

      if (!!inviteResponse['account']['show_biometric_consent'] && inviteResponse['account']['show_biometric_consent']) {
        this.localStorage.setItem('show_biometric_consent', inviteResponse['account']['show_biometric_consent']);
      }
      
      if (!!inviteResponse && !!inviteResponse['account'] && !!inviteResponse['account']['accent_character_translation_enabled']) {
        this.localStorage.setItem('accent_character_translation_enabled', inviteResponse['account']['accent_character_translation_enabled']);
      }
      if (!!inviteResponse && !!inviteResponse['account'] && inviteResponse['account']['Switch_url']) {
        this.localStorage.setItem('switch_url', inviteResponse['account']['Switch_url']);
      }
      if (!!inviteResponse && !!inviteResponse['account'] && inviteResponse['account']['enable_yoti_with_switch']) {
        this.localStorage.setItem('enable_yoti_with_switch', inviteResponse['account']['enable_yoti_with_switch']);
      }      
      if (!!inviteResponse && !!inviteResponse['account'] && !!inviteResponse['account']['rid_retry_count']) {
        this.localStorage.setItem('rid_retry_count', parseInt(inviteResponse['account']['rid_retry_count']));
      }
      
      if (!!inviteResponse && !!inviteResponse['account'] && inviteResponse['account']['esignature_min_size']) {
        this.localStorage.setItem('esignature_min_size', parseInt(inviteResponse['account']['esignature_min_size']));
      }
   }

    this.localStorage.setItem('account_id', inviteResponse['account']['id']);
    this.localStorage.setItem('visiter_id', uuidv4());

    /**
     * 'Contact Us' is behind a feature flag now. (features.config.ts)
     * false - means off i.e. no 'Contact Us' link
     * true - means on i.e. 'Contact Us' link to be shown if present in invite response object
     */

    this.localStorage.setItem('active_chat', inviteResponse['account']['active_chat']);
    // TODO - Add shutdown SFC

    if (AppConfig.isFeatureAvailable('app-features', 'contact-us')
      && this.localStorage.getItem('active_chat') !== undefined
      && this.localStorage.getItem('active_chat') === 'N') {
      if (inviteResponse['account'] !== undefined && inviteResponse['account']['contactus_text'] !== undefined) {
        this.localStorage.setItem('contactus', inviteResponse['account']['contactus_text']);
        this.eventBus.contactUsAnnounced({
          contactUs: inviteResponse['account']['contactus_text'],
          isContactUsReceived: true
        });
      }
    }

    this.localStorage.setItem('f_name', inviteResponse['applicant']['first_name']);
    if (inviteResponse['applicant']['middle_initial'] === undefined) {
      this.localStorage.setItem('m_name', '');
    } else {
      this.localStorage.setItem('m_name', inviteResponse['applicant']['middle_initial']);
    }
    this.localStorage.setItem('l_name', inviteResponse['applicant']['last_name']);

    if (inviteResponse !== undefined && inviteResponse['applicant'] !== undefined) {
      this.localStorage.setItem('pending_info_due_date', inviteResponse['applicant']['pending_info_due_date']);
    }

    if (inviteResponse !== undefined && inviteResponse['applicant'] !== undefined) {
      this.localStorage.setItem('applicant_id', inviteResponse['applicant']['id']);
    }

    if (!!inviteResponse['workflow_instance']['id']) {
      console.log('wid ', inviteResponse['workflow_instance']['id']);
      this.localStorage.setItem('workflow_instance_id', inviteResponse['workflow_instance']['id']);
      this.localStorage.setItem('inviteId', inviteResponse['id']);
      this.localStorage.setItem('invite_id', inviteResponse['id']);
      this.localStorage.setItem('wps_number', inviteResponse['applicant']['provider_ref_number']);
    } 
    
    if (inviteResponse['applicant'] !== undefined &&  inviteResponse['applicant']['data'] !== undefined
      && inviteResponse['applicant']['data']['APPLICANT_STATUS'] !== undefined ) {
      this.localStorage.setItem('review_status', inviteResponse['applicant']['data']['APPLICANT_STATUS']);
    }
    
    console.log('test wid ', this.localStorage.getItem('workflow_instance_id'))
    if (!!inviteResponse['account']['name']) {
      this.localStorage.setItem('company_name', inviteResponse['account']['name']);
    }

    this.localStorage.setItem('is_gender_mandatory', inviteResponse['applicant']['is_gender_mandatory']);

    this._shareService.raisingEventTermsServiceHide(this.eventBus, false);

    if (inviteResponse['applicant']['status'] === 'DA_REDIRECT') {
      // this._next('/da-redirect');
      // DA REDIRECT this._next(response['/da-redirect']);
      this.localStorage.setItem('da_url', inviteResponse['da_url']);
      $('#plsWaitOnIAgreeModal').modal('hide');
      this._router.navigate(['/profile/redirect']);
    } else {
      let counsentCountry = inviteResponse['current_address_country'];
      this.localStorage.setItem('is_country_based_consent', inviteResponse['is_country_based_consent']);

      // Inform header component to disable language Icon after login only for EIAF.
      if (this.isInviteEiaf) {
        this._eventBus.languageListInInviteReceivedAnnounced({
          availableLangList: []
        });
      }

      // Check invite is RightId or Eiaf or Mi
      const isInviteTypeEnabled = this.shareService.processInviteType();
      const isInviteKYP = this.shareService.checkInviteIsKYP();
      const isRidInvite = this.shareService.checkInviteIsRightId();
      const showLanguageSelection = this.localStorage.getItem('show_language_selection');
      let availableLangList =  this.localStorage.getItem('availableLanguageList');
      
      //skip language selection page
      let skipLanguageSelection = !this.isAuthyLoggedIn || (!availableLangList || availableLangList.length === 0) || (isInviteTypeEnabled && !isRidInvite) || !showLanguageSelection ? true : false;
      let inviteTypeNotification = this.shareService.checkInviteIsNotification();
      
      if (inviteTypeNotification) {
        this.localStorage.setItem('notification_mode', 'true');
        $('#plsWaitOnIAgreeModal').modal('hide');
        if (!skipLanguageSelection) {
          this._next('/language-selection');
        } else {
          this._next('/dashboard');
        }
        return;
      }
       
      let applicantInstructions = inviteResponse['applicant']['instructions'];
      if (!!applicantInstructions && applicantInstructions.length > 0 &&
        !(inviteResponse['applicant']['status'] === 'SUBMITTED' ||
          inviteResponse['applicant']['status'] === 'INACTIVE' ||
          inviteResponse['applicant']['status'] === 'CONSENT_DECLINE'||
          inviteResponse['applicant']['status'] === 'INVITE_FAILURE' ||
          inviteResponse['applicant']['status'] === 'ASYNC_SUBMITTED' ||
          inviteResponse['applicant']['status'] === 'PENDING_REVIEW')) {
        this.localStorage.setItem("applicantInstructions", applicantInstructions);
        $('#plsWaitOnIAgreeModal').modal('hide');
        this._next('/instructions/customerInstructions');
      } else if (!skipLanguageSelection) {
        $('#plsWaitOnIAgreeModal').modal('hide');
        this._next('/language-selection');
      } else if (inviteResponse['is_country_based_consent'] && counsentCountry === undefined
      	&& (!isInviteTypeEnabled || (this.isInviteRightId && subType === AppConfig.RIGHT_ID_ROUTER
        && (ridStatus && ridStatus === 'OPTED_OUT_DEVICE' || ridStatus && ridStatus === 'OPTED_OUT' || ridStatus && ridStatus === 'DERIVED'))
        || (this.isInviteRightId && isInviteKYP && !!ridStatus && ridStatus !=='PENDING') )) {
          $('#plsWaitOnIAgreeModal').modal('hide');
        this._next('/country-consent');
      } else {
        this._workflow.getWorkFlow(inviteResponse['workflow_instance']['id']).subscribe(response => {
          this.headerNameMasking(inviteResponse['applicant']);
          $('#plsWaitOnIAgreeModal').modal('hide');
          if (this.isInviteRightId && subType === AppConfig.RIGHT_ID_ROUTER) {
            if (!this.isRightIdStageCompleted()) {
              if (!isYoti && this.deviceService.isDesktop()) {
                this.errorMessage = <any>Error;
                this.errorMessage['errorMessage'] = 'UNSUPPORTED_DEVICE';
                this.shareService.logFlow("login", "unsupported-device", "after getworkflow, " + this.shareService.getDeviceDetails());
                this._shareService.redirectToUnsupportedDevice(this._service,this._router);
                return;
              }

              this.trackObj = {
                stage_name: 'login',
                action_value: 'save'
              };
        
              window.track_event('rightid invite, load js', this.trackObj, true, true);

              //Adding IDMetricsCaptureFramework.js to head tag if rightID
              let head = this.document.getElementsByTagName('head')[0];
              let script = document.createElement('script');
              script.src = '../../assets/js/IDMetricsCaptureFramework.js';
              head.appendChild(script);
            } else if (inviteResponse['is_country_based_consent'] && counsentCountry === undefined) {
              this._next('/country-consent');
              return;
            }
        }

        
        if (inviteResponse['applicant']['status'] === 'SUBMITTED' ||
            inviteResponse['applicant']['status'] === 'INACTIVE' ||
            (inviteResponse['applicant']['status'] === 'CONSENT_DECLINE' && ridStatus !== 'BIOMETRIC_DECLINED' && ridStatus !== 'VENDOR_BIOMETRIC_DECLINED')  ||
            inviteResponse['applicant']['status'] === 'INVITE_FAILURE' ||
            inviteResponse['applicant']['status'] === 'ASYNC_SUBMITTED' ||
            inviteResponse['applicant']['status'] === 'PENDING_REVIEW') {
            this._nextWithSecurityQuestion('/dashboard');
          } else {
            if (!this.isInviteMI) {
              this._workflow.getDynamicCDConfig(null);
            }

            this._workflow.storeDocBaseUrl();
            this._nextWithSecurityQuestion(response['URL']);
          }
        }, error => {
          $('#plsWaitOnIAgreeModal').modal('hide');
        });
      }

      this.getCountryNameByCountryCode(counsentCountry);
    }
  }

  getCountryNameByCountryCode(countryCode) {
    if (countryCode !== undefined) {
      let body = {};
      body['countryParam'] = countryCode;
      this._shareService.countryLookUpServiceByCountryCode(body).subscribe(response => {
        let countryData = response;
        let countryList = countryData['country_list'];

        if (countryList !== undefined && countryList.length === 1) {
          this.localStorage.setItem('consentCountry', JSON.stringify(countryList[0]));
        }
      });
    }
  }

  _nextWithSecurityQuestion(url) {
    let secretQuestionSet = this.localStorage.getItem('secret_question_set');

    if (secretQuestionSet === 'false') {
      this.nextUrl = url;
      this.openSecurityQuestionModal();
    } else {
      this._next(url);
    }
  }

  private openSecurityQuestionModal() {
    jQuery("#security_question_modal").modal("show");
  }

  closeAndSaveSecurityQuestionModel() {
    jQuery("#security_question_modal").modal("hide");
    let email = this.localStorage.getItem('user_email');

    this._profileService.updateSecurityInfo(email, this.secretQuestion, this.secretAnswer).subscribe(response => {
      this._next(this.nextUrl);
    },
      error => {
        this.trackObj = {
          stage_name: 'login',
          user_email: this.localStorage.getItem('user_email'),
          action_value: 'security question failed'
        };

        window.track_event('invalid security answers', this.trackObj, true, true);

        this._next(this.nextUrl);
      });
  }

  logout(): void {
    this._service.logout();

    // TODO - Add shutdown SFC and boot SFC
    
    this._next('./login');
  }

  redirectForgotPassword(value: Object): void {
    this.localStorage.setItem('applicant_email', value['email']);
    this._next('./profile/forgot-password');
  }

  private sendStateToServer(state: any): void {
    /*
     * Calculate the length of state object and take the last item in the list, since that will be
     * the last state before leaving the application.
    */
    let stateCount = state.length;

    let stateData = state[stateCount - 1];

    let config = this._workflow.getStepConfig(stateData['name'], stateData['stepIndex']);
    let action = config['action'];

    stateData['action'] = action;

    // For 'NEXT' & 'SAVE & EXIT' data with status already sent from individual Component
    this._service.sendStateToServer(stateData).subscribe(response => {
      // Status sent to Server successfully
    }, error => {
      console.log(error);
    });
  }

  private _next(url): void {
    this._router.navigate([url]);
  }

  /**
   *
   * @param profileRes -- Takes the profile service response and sets the localstorage value as required
   */

  private saveToLocalUserData(profileRes: Object) {
    // Check if first name, middle name and last name exist in local. If yes, override them, else store in local
    // Checking if first name exists
    if (this.localStorage.getItem('first_name') === undefined) {
      this.localStorage.setItem('first_name', profileRes['first_name']);
    }

    // Checking if has_middle_name is true and then storing middle name
    if (profileRes['has_middle_name']) {
      if (this.localStorage.getItem('middle_name') === undefined) {
        this.localStorage.setItem('middle_name', profileRes['middle_name']);
      }
    }

    // Checking if last name exists
    if (this.localStorage.getItem('last_name') === undefined) {
      this.localStorage.setItem('last_name', profileRes['last_name']);
    }

    if (profileRes['name_suffix'] === null || profileRes['name_suffix'] === undefined ||
      profileRes['name_suffix'] === 'undefined') {
      this.localStorage.setItem('suffix_name', '');
    }
  }

  validateEmail(value: string) {
    let loginInputEmail = value;
    let regexVal = AppConstants.EMAIL_REGEX;
    let emailRegex = new RegExp(regexVal);

    if (loginInputEmail == "") {
      this.isEmailError = true;
      this.isEmailErrorInfo = true;
      this.emailErrorMessage = 'LOGIN_EMAIL_ERROR_MSG';
      this.isPwdValid = false;
    }
    else if (!!loginInputEmail && !emailRegex.test(loginInputEmail)) {
      this.isEmailError = true;
      this.isEmailErrorInfo = true;
      this.emailErrorMessage = 'LOGIN_EMAIL_FORMAT_ERROR_MSG';
      this.isPwdValid = false;
    }
    else {
      this.isEmailError = false;
      this.isEmailErrorInfo = false;
      this.emailErrorMessage = '';
      this.isEmailValid = true;
    }
  }

  enableButton(secretQuestion: string, secretAnswer: string) {
    this.validateSecretQuestion(secretQuestion);
    this.validateSecretAnswer(secretAnswer);
  }

  validateSecretQuestion(secretQuestion: string) {
    if (secretQuestion && secretQuestion.length > 0) {
      this.isSecretQuestionValid = true;
    } else {
      this.isSecretQuestionValid = false;
    }
  }

  validateSecretAnswer(secretAnswer: string) {
    if (secretAnswer && secretAnswer.length > 0) {
      this.isSecretAnswerValid = true;
    } else {
      this.isSecretAnswerValid = false;
    }
  }

  closeEmailError() {
    this.isEmailError = false;
  }

  closePwdError() {
    this.isPwdError = false;
  }

  closeServerError() {
    this.isError = false;
  }

  validateOnPwd(emailValue: string, pwdValue: string) {
    if (emailValue != "") {
      if (pwdValue.length > 0) {
        this.isEmailValid = true;
        this.isPwdValid = true;
      }
      else {
        this.isEmailValid = false;
        this.isPwdValid = false;
      }
    }
  }

  removeReadOnly() {
    $('#loginInputPwd').removeAttr('readonly');
  }

  hideError() {
    this.isError = false;
  }

  // loadTranslation(inviteResponse: any) {
  //   /*
  //    * Langugage is already set when app is loaded. Get the default selected language
  //    * inside loadTranslations.
  //    */
  //   this.shareService.processLoadTranslation(inviteResponse['workflow_instance']['id']);
  // }

  /**
   * check right id stage is completed or not
   */
  isRightIdStageCompleted() {
    const completedMenus = this._workflow.getAllCompletedStageMenu();
    if (!!completedMenus && completedMenus.length > 0)  {
      const menus = completedMenus.filter(menu => menu === 'rightid-data-v1');
      return !!menus && menus.length > 0 ? true : false;
    }

    return false;
  }

  setApplicantDataInLocalStorage(applicateData) {
    this.localStorage.setItem('first_name', applicateData['first_name']);
    this.localStorage.setItem('applicant_email', applicateData['email']);
    this.localStorage.setItem('last_name', applicateData['last_name']);
    this.localStorage.setItem('mobile', applicateData['mobile']);
  }

  headerNameMasking(inviteResponse) {
    let nameConfig = this._workflow.getStepConfig('main-data-v1', LoginComponent.NAME_STEP_INDEX);
    let value = this.shareService.getEaPreferenceValueByKey(nameConfig['ea-preferences'], LoginComponent.NAME_MASKING_PREFERNCE_VALUE);
    let firstName = inviteResponse['first_name'];
    let lastName;
    if(value === "YES"){
      lastName = '*****'
    } else {
      lastName = inviteResponse['last_name'];
    }
    this.eventBus.announceMission({
      usename: firstName + this.middlename + lastName,
      isUserInfoShown: true
    });
  }

  checkLogin(formValue: Object) {
    let inviteKey = this.localStorage.getItem('invite_key');
    //Checking if logged in with base url
    if (!inviteKey) {
      let that = this;
      let email = formValue['email'];
      $('#plsWaitOnIAgreeModal').modal('show');
      this._preAuthService.getAuthEngineByEmail(email).subscribe(response => {
        let isAuthyEnabled = (response['auth_engine'] === 'AUTHY');
        if (!!isAuthyEnabled) {
          if(!!response['key']) {
            this.localStorage.setItem("invite_key", response['key']); //without key fetch-authy-token fails
            this._router.navigate(['/authy/authentication']);
            return;
          } else {
            that.authyService.loginRedirect({
            authority: environment.policies.authorities.signIn.authority,
            scopes: environment.policies.scopes,
            prompt: 'login',
            extraQueryParameters: { login_hint: encodeURIComponent(email ?? '') },
          });

        }

        } else {
          that.postLogin(formValue);
        }
      }, error => {
        console.log("Error while fetching AuthEngineByEmail ", error);
        if (!!error && !!error.errorMessage) {
          that.errorMessage = that._translate.translate('INVALID_LOGIN');
        } else {
          that.errorMessage = that._translate.translate('GENERIC_ERROR_MESSAGE');
        }
        that.isError = true;
        $('#plsWaitOnIAgreeModal').modal('hide');
      });
    } else {
      this.postLogin(formValue);
    }
  }
  
  private loginSuccess(response) {
    this.trackObj = {
      stage_name: 'login',
      action_value: 'sign in'
    };

    this.dynamicScriptLoader.loadScripts();

    window.track_event('sign in completed', this.trackObj, true, true, true);

    // Post message
    window.safeNotifyHost('workflow_started', null);
    /*
    * MAKE getProfile API call to get User info to show in Header
    *
    * Two actions:
    * ---- 1. Raise an event Update Header
    * ---- 2. Redirect to next Route
    */
    //get the profile data but should be using email not profile id
    let profile_id = this.localStorage.getItem('profile_id');
    let invite_id = this.localStorage.getItem('inviteId');
    $('#plsWaitOnIAgreeModal').modal('show');
    this._profileService.getProfile(profile_id).subscribe(profileResponse => {
      this.saveToLocalUserData(profileResponse);

      if (profileResponse['has_middle_name']) {
        if (profileResponse['middle_name'] !== undefined) {
          this.middlename = " " + profileResponse['middle_name'] + " ";
        } else {
          this.middlename = " ";
        }
      } else {
        this.middlename = " ";
      }
      this.localStorage.setItem("middlename", this.middlename);

      if (invite_id !== undefined) {
        this._profileService.inviteById(invite_id).subscribe(async (inviteResponse) => {
          this._eventBus.languageListInInviteReceivedAnnounced({
            hideLanguageSelectorInLoginPage: true
          });
          
          await this.processResponse(inviteResponse);
          
          const ridStatus = this.localStorage.getItem('rid_status');
          const subType = this.localStorage.getItem('subType');

          if (!(this.isInviteRightId && this.deviceService.isDesktop() && (!subType || (subType === AppConfig.RIGHT_ID_ROUTER && ridStatus === 'PENDING')))) {
            window.track_event("invite by id call", { stage_name: 'login', action_value: 'invite call' }, true, true);

            this.eventBus.announceMission({
              usename: profileResponse['first_name'] + this.middlename + profileResponse['last_name'],
              isUserInfoShown: true
            });
          }
        },
        error => {
          // Redirect to expired page while login from invite link
          $('#plsWaitOnIAgreeModal').modal('hide');
          this.errorMessage = <any>error;
          if (this.errorMessage === 'INVITE EXPIRED') {
            this._service.logout();
            this._next('/expired');
          }
        });
      } else {
        this._profileService.inviteByProfile(profile_id).subscribe(async (inviteResponse) => {
          this._eventBus.languageListInInviteReceivedAnnounced({
            hideLanguageSelectorInLoginPage: true
          });
          
          await this.processResponse(inviteResponse);
          
          if (!(this.isInviteRightId && this.deviceService.isDesktop())) {
            window.track_event("invite by profile call", { stage_name: 'login', action_value: 'invite call' }, true, true);
     
            this.eventBus.announceMission({
              usename: profileResponse['first_name'] + this.middlename + profileResponse['last_name'],
              isUserInfoShown: true
            });
          }
        },
        error => {
          // Redirect to expired page while login with emailid
          $('#plsWaitOnIAgreeModal').modal('hide');
          this.errorMessage = <any>error;
          if (this.errorMessage === 'INVITE EXPIRED') {
            this._service.logout();
            this._next('/expired');
          }
        });
      }
    },
    error => {
      $('#plsWaitOnIAgreeModal').modal('hide');
      this.errorMessage = <any>error;
      this.isError = true;
    });
  }

  private isMFAError(error: {}) {
    if(!!error && !!error['errorCode']) {
      return !!(!!error && !!error['additionalProperties'] && !!error['additionalProperties']['additional_error_detail'] && !!error['additionalProperties']['additional_error_detail']['action'] && error['additionalProperties']['additional_error_detail']['action']==='INVALID_PASSWORD_UP');
    }
    
    return false;
  }
}
