import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import Clarity from "@microsoft/clarity";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ClarityService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public clarityQueue = [];

  private isInitialized = false;

  public initialize(): void {
    if (!this.isClarityIdValid(environment["clarity_id"])) {
      return;
    }

    if (!this.isInitialized) {
      Clarity.init(environment["clarity_id"]);
      this.initializeClarityQueue.bind(this);

      const scriptElement = this.document.createElement("script");
      scriptElement.async = true;
      scriptElement.src = `https://www.clarity.ms/tag/${environment["clarity_id"]}`;
      scriptElement.onload = this.onScriptLoaded;
      const firstScriptElement =
        this.document.getElementsByTagName("script")?.[0];
      if (firstScriptElement?.parentNode) {
        firstScriptElement.parentNode.insertBefore(
          scriptElement,
          firstScriptElement
        );
      } else {
        this.document.body.appendChild(scriptElement);
      }
      console.log("Clarity event tracking started.");
    }
  }

  public logEvent(name: string, payload: any): void {
    try {
      if (this.isInitialized) {
        const eventName = `${payload["stage_name"]} - ${payload["action_value"]} - ${name}`;
        Clarity.event(eventName);
      }
    } catch (e) {
      console.error("Clarity event tracking failed.");
    }
  }

  public bootUser(response: Object): void {
    if (!response) {
      console.error("User info could not be set for Clarity.");
      return;
    }

    try {
      Clarity.identify(response["key"], response["profile_id"]);
      Clarity.setTag("profileId", response["profile_id"] ?? "");
      Clarity.setTag("accountId", response["account"]?.["id"] ?? "");
      Clarity.setTag("packageCode", response["package"]?.["code"] ?? "");
      Clarity.setTag("packageName", response["package"]?.["name"] ?? "");
      Clarity.setTag("inviteKey", response["key"] ?? "");
    } catch (e) {
      console.error("Clarity boot has failed.");
    }
  }

  public shutdown(): void {
    this.logEvent("shutdown", "Shutting down Clarity service.");
  }

  public updateState(newPage: string): void {
    const eventName = `updateState: ${newPage}`;
    this.logEvent(eventName, "Updating Clarity state.");
  }

  public upgrade(upgradeReason: string): void {
    this.logEvent(
      "upgrade",
      `Upgrading Clarity session with reason: ${upgradeReason}.`
    );
    Clarity.upgrade(upgradeReason);
  }

  private isClarityIdValid(clarityId: string): boolean {
    if (clarityId && typeof clarityId === "string" && clarityId.length > 0) {
      return true;
    }
    console.error("Clarity Id is not set properly.");
    return false;
  }

  private onScriptLoaded = (): void => {
    this.isInitialized = true;
  };

  private initializeClarityQueue = (...args: string[]): void => {
    (this.clarityQueue = this.clarityQueue || []).push(args);
  };
}
