import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'framework/localstorage.service';
import {SharedService} from '../../shared/services/shared.service';

declare var window: any;

@Component({
  selector: 'app-base-forgot-password',
  templateUrl: './base-forgot-password.component.html',
  styleUrls: ['./base-forgot-password.component.css']
})
export class BaseForgotPasswordComponent implements OnInit {

  selectedPage = 'KEYCLOAK';

  constructor( ) { }

  ngOnInit(): void {
    window.track_event( this.selectedPage + ' selected', { stage_name: 'base-forgot-password', action_value: 'load' }, true, true);
  }
}
