import { Injectable, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LanguageInit } from "framework/i18n/language.init";
import { LocalStorageService } from "framework/localstorage.service";
import { ClarityService } from "./clarity.service";
import { jwtDecode } from 'jwt-decode';
import { SharedService } from "./shared/services/shared.service";


@Injectable({
    providedIn: 'root'
})
export class AppInit {
    constructor(private languageInit: LanguageInit,
                private localStorage: LocalStorageService,
                private shareService: SharedService,
                private _router: Router,
                private _clarity: ClarityService) {
      // MFA  redirect , capture the tokens and route to mfa call back.
      this.localStorage.clear();
      this._clarity.initialize();
      
      if ((window.location.href.indexOf('type') > 0) || window.location.href.indexOf('#state=') > 0 || window.location.href.indexOf('#error=') > 0) {
        const query = new URLSearchParams(window.location.search);
        if (!!query && query.has('type')) {
          let authyToken = query.get('token');
          if (!!authyToken) {
            const decodedToken = jwtDecode(authyToken);
            window.sessionStorage.setItem("Authy_subject", decodedToken.sub);
            this.shareService.logAuthyFlow("app", "authy_token_received", "type " + query.get("type"));
          }
        }
        this.localStorage.setItem("isAuthyCallback", true);
      }
    }

    async initialize() {
      // console.log("app.init initialize start---");
      await this.languageInit.initialize();
      // console.log("app.init initialize finish---");
    }
}
