import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

declare var window: any;

@Injectable()
export class ProfileService {
  isLoggedIn: boolean = false;
  headerOptions: any;

  constructor(private _http: HttpClient,
    private _workflow: WorkflowService,
    private _localStorage: LocalStorageService
  ) {
    this.headerOptions = this._workflow.getHeaderOptions();
  }

  static getSecurityQuestions(): Array<Object> {
    let types = [
      { id: 'secret_question_city', name: 'SECRET_QUESTION_TYPE_CITY' },
      { id: 'secret_question_school', name: 'SECRET_QUESTION_TYPE_SCHOOL' },
      { id: 'secret_question_father', name: 'SECRET_QUESTION_TYPE_FATHER' },
      { id: 'secret_question_mother', name: 'SECRET_QUESTION_TYPE_MOTHER' },
      { id: 'secret_question_pet', name: 'SECRET_QUESTION_TYPE_PET' },
      { id: 'secret_question_spouse', name: 'SECRET_QUESTION_TYPE_SPOUSE' }
    ];
    return types;
  }

  validatePassword(value: Object): Observable<Object> {
    let body = {
      "key": this._localStorage.getItem('key'),
      "password": value['password']
    };

    this.headerOptions = this._workflow.getHeaderOptions();
    return this._http.post(AppConfig.API_ENDPOINT() + '/api/v1/validate-password', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => this.handleError('password_policy_not_met', err))
      );
  }

  setPassword(value: Object): Observable<Object> {
    let body = {
      "key": this._localStorage.getItem('key'),
      "profile_id": this._localStorage.getItem('profile_id'),
      "first_name": this._localStorage.getItem('first_name'),
      "last_name": this._localStorage.getItem('last_name'),
      "email": this._localStorage.getItem('applicant_email'),
      "old_email": this._localStorage.getItem('applicant_email'),
      "password": value['password'],
      "secret_question": value['secretQuestion'],
      "secret_answer": value['secretAnswer']
    };

    let traces = {};
    traces['action'] = 'SignUp';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._http.post(AppConfig.API_ENDPOINT() + '/api/v1/registration', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doActionForRegistration(res)),
        catchError(err => this.handleError('registration_api_failed', err))
      );
  }

  /**
   * do the registration with oidc service
   *
   * @param value
   * @returns
   */
  oidcUserRegistration(value: Object): Observable<Object> {
    this._localStorage.setItem('stateId',  uuidv4());
    console.log('stateId', this._localStorage.getItem('stateId'));

    let mfaValue = !!this._localStorage.getItem('pa_enable_mfa') ? this._localStorage.getItem('pa_enable_mfa'): '';
    
    let body = {
      "key": this._localStorage.getItem('key'),
      "systemId": this._localStorage.getItem('system_id'),
      "firstName": this._localStorage.getItem('first_name'),
      "lastName": this._localStorage.getItem('last_name'),
      "email": this._localStorage.getItem('applicant_email'),
      "oldEmail": this._localStorage.getItem('applicant_email'),
      "password": value['password'],
      "sessionId" : this._localStorage.getItem('stateId'),
      "additionalProperties": {
        "key": this._localStorage.getItem('invite_key'),
        "profile_id" : this._localStorage.getItem('profile_id'),
        "invite_id" :  this._localStorage.getItem('invite_id'),
        "email": this._localStorage.getItem('applicant_email'),
        "custom_styles": this._localStorage.getItem('custom_styles'),
        "language": this._localStorage.getItem('language'),
        "language_country_code": this._localStorage.getItem('language_country_code'),
        "enableMfa": mfaValue
      }
    };

    let traces = {};
    traces['action'] = 'SignUp';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._http.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/registration', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doOidcActionForRegistration(res)),
        catchError(err => this.handleError('oidc registration_api_failed', err))
      );
  }

  handleError(eventName, error) {
    console['server'](eventName, error);

    if (error.status === 500) {
      console.log('ERROR CODE 500');
      return throwError(new Error(error.status));
    } else if (error.status === 400) {
      console.log('ERROR CODE 400');
      return throwError(new Error(error.status));
    } else if (error.status === 409) {
      console.log('ERROR CODE 409');
      return throwError(new Error(error.status));
    } else if (error.status === 406) {
      console.log('ERROR CODE 406');
      return throwError(new Error(error.status));
    } else if (error.status === 502) {
      console.log('ERROR CODE 502');
      return throwError(new Error(error.status));
    } else if (error.status === 404) {
      console.log('ERROR CODE 404');
      return throwError(new Error(error.status));
    } else {
      return throwError(new Error(error.status));
    }
  }

  getlanguageList() {
    return this._localStorage.getItem('allowed_language');
  }

  getProfile(value: any): Observable<Object> {
    this.headerOptions = this._workflow.getHeaderOptions();

    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/profile/' + value + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this.handleError('get_profile_failed', err))
      );
  }

  putInviteWithSelectedCountry(countryCode): Observable<Object> {
    let inviteBody = {
      'key': this._localStorage.getItem('key'),
      'current_address_country': countryCode
    };

    let traces = {};
    traces['action'] = 'WorkflowStarted';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._http
      .put(AppConfig.API_ENDPOINT() + `/api/v1/invite`, inviteBody, this.headerOptions)
      .pipe(
        map(res => res),
        catchError(err => this._handleCountryError('invite_selected_country_failed', err))
      );
  }

  inviteByProfile(profileId: string): Observable<Object> {
    /*
     * Need to make the HTTP GET API call with Token and will return
     * 410 - expired - > Login
     * 200 - > Set Password
    */

    return this._http
      .get(AppConfig.API_ENDPOINT() + `/api/v1/profile-lookup/invite?profileId=${profileId}`, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doInviteByProfileAction(res)),
        catchError(err => this._handleError('invite_by_profile_failed', err))
      );
  }

  inviteById(inviteId: any): Observable<Object> {
    /*
     * Need to make the HTTP GET API call with Token and will return
     * 410 - expired - > Login
     * 200 - > Set Password
    */

    return this._http
      .get(AppConfig.API_ENDPOINT() + `/api/v1/invite/` + inviteId, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doInviteByProfileAction(res)),
        catchError(err => this._handleError('invite_by_id_failed', err))
      );
  }

  unsubscribeFromSmsByInviteKey(inviteKey: string): Observable<Object> {
    return this._http
      .put(AppConfig.API_ENDPOINT() + `/api/web/invite/unsubscribe/` + inviteKey, {})
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doUnsubscribeSMSAction(res)),
        catchError((error: any) => {
          console['server']('unsubscribe_sms_invite_failed', error);
          // console.log('ERROR CODE ' + error.status);
          return throwError(new Error(error.status));
        }));
  }

  getUnsubscribeFromSmsByInviteKey(inviteKey: string): Observable<Object> {
    return this._http
      .get(AppConfig.API_ENDPOINT() + `/api/web/invite/unsubscription/` + inviteKey)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doUnsubscribeSMSAction(res)),
        catchError((error: any) => {
          console['server']('get_unsubscription_failed', error);
          // console.log('ERROR CODE ' + error.status);
          return throwError(new Error(error.status));
        }));
  }

  updateSecurityInfo(email: string, secret_question: string, secret_answer: string): Observable<Object> {
    console.log("update security Info for ----:" + email + ":" + secret_question + ":" + secret_answer + ":");
    let body = {
      "email": email,
      "secret_question": secret_question,
      "secret_answer": secret_answer
    };
    this.headerOptions = this._workflow.getHeaderOptions();

    return this._http.put(AppConfig.API_ENDPOINT() + '/api/v1/registration/security', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doProfileAction(res)),
        catchError(err => this.handleError('update_security_info_failed', err)));
  }

  getSecurityInfo(email: string): Observable<Object> {
    let body = {
      "email": email
    };
    this.headerOptions = this._workflow.getHeaderOptions();
    
    return this._http.post(AppConfig.API_ENDPOINT() + '/api/v1/registration/security', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doGetSecurityAction(res)),
        catchError(err => this.handleError('get_security_info_failed', err)));

  }

  private _doGetSecurityAction(response: Object) {
    this._localStorage.setItem('secret_question', response['secret_question']);

    return response;
  }

  private _extractData(res: Object) {
    return (res) || {};
  }

  private _doAction(response: Object) {
    if (this._localStorage.getItem('profile_id') == null) {
      this._localStorage.setItem('profile_id', response["attributes"]['profile_id'][0]);
    }
    this.isLoggedIn = true;

    return response;
  }

  private _doOidcActionForRegistration(response: Object) {
    console.log('res is: ', response);
    if(!!response && !!response['systemId'] && !!response['stateToken'] && !!response['status'] && !!response['factors'] && !!response['factors']['0'] && !!response['user']) {
      this._localStorage.setItem("oidc_lgn_res",response);
      this._localStorage.setItem('oidc_st',response['stateToken']);
      this._localStorage.setItem("profile_id",(response['systemId'] || response['system_id']));

      return response['stateToken'];
    }
    else if (!!response && !!response['accessToken']) {
      if (this._localStorage.getItem('profile_id') == null) {
        this._localStorage.setItem('profile_id', response["attributes"]['additionalProperties']['profile_id'][0]);
      }
      
      this._localStorage.setItem('access_token', response['accessToken']);
      this._localStorage.setItem('session_state', response['sessionState']);
  
      this.isLoggedIn = true;
      this._workflow.getHeaderOptions();
  
      return response;
    }
    else {
      return null;
    }
  }

  private _doActionForRegistration(response: Object) {
    if (this._localStorage.getItem('profile_id') == null) {
      this._localStorage.setItem('profile_id', response["attributes"]['profile_id'][0]);
    }

    this._localStorage.setItem('access_token', response['access_token']);
    this._localStorage.setItem('session_state', response['session_state']);
    console.log("session_state :", response['session_state']);

    this.isLoggedIn = true;
    this._workflow.getHeaderOptions();

    return response;
  }

  private _doProfileAction(response: Object) {
    return response;
  }

  private _doInviteByProfileAction(response: Object) {
    return response;
  }

  private _doUnsubscribeSMSAction(response: Object) {
    return response;
  }

  forgotPassword(emailID): Observable<Object> {
    this._localStorage.setItem('applicant_email', emailID);
    let lang = this._localStorage.getItem('language') !== undefined ? this._localStorage.getItem('language') : "";

    let body = {
      "email": emailID,
      "language": lang
    }
    return this._http
      .post(AppConfig.API_ENDPOINT() + `/api/v1/password/forgot`, body)
      .pipe(
        map(res => this._extractDataForForgotPassword(res)),
        map(res => this._doActionForForgotPassword(res)),
        catchError(err => this._handleError('forgot_passwrd_failed', err)));
  }

  private _extractDataForForgotPassword(res: Object) {
    return (res) || {};
  }

  private _doActionForForgotPassword(response: Object) {
    this._localStorage.setItem('otp', response['otp']);

    return response;
  }

  resetPassword(value): Observable<Object> {
    let body = {
      "otp": this._localStorage.getItem('otp'),
      "email": value['email'],
      "new_password": value['password'],
      "secret_question": value['secretQuestion'],
      "secret_answer": value['secretAnswer']
    };

    return this._http
      .post(AppConfig.API_ENDPOINT() + `/api/v1/password/reset`, body, this.headerOptions)
      .pipe(
        map(res => this._extractDataForResetPassword(res)),
        map(res => this._doActionForResetPassword(res)),
        catchError((error: any) => {
          let errorMessage: string;
          console['server']('reset_pwd_failed', error);

          switch (error.status) {
            case 400:
                errorMessage = '400_ERROR';
                break;
            case 409:
              errorMessage = '409_ERROR';
              break;
            case 500:
              errorMessage = 'HEAVY LOAD';
              break;
            case 401:
              let _body = error || {};
              errorMessage = error.status + ":" + _body['error']['error'];
              break;
            case 410:
              //Bad request
              errorMessage = 'KEY EXPIRED';
              break;
            case 404:
              break;
            case 423:
              errorMessage = 'ACCOUNT_LOCKED';
              break;
          }
          return throwError(errorMessage);
        })
      );
  }

  private _extractDataForResetPassword(res: Object) {
    return (res) || {};
  }

  private _doActionForResetPassword(response: Object) {
    return response;
  }

  validateOtp(otp: string): Observable<Object> {
    this._localStorage.setItem('otp', otp);

    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/password/otp/' + otp + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractDataForValidatePassword(res)),
        map(res => this._doActionForValidatePassword(res)),
        catchError(err => this._handleError('validate_otp_failed', err)));
  }

  private _extractDataForValidatePassword(res: Object) {
    return (res) || {};
  }

  private _doActionForValidatePassword(response: Object) {
    this._localStorage.setItem('applicant_email', response['email']);
    return response;
  }

  private _handleError(eventName, error: any, isLogError?: boolean) {
    let _body = error || {};
    let errorMessage: string;

    if (error.status !== 410)
      console['server'](eventName, error);

    switch (error.status) {
      case 400:
          errorMessage = '400_ERROR';
          break;
      case 409:
        errorMessage = '409_ERROR';
        break;
      case 500:
        errorMessage = 'HEAVY LOAD';
        break;
      case 401:
      case 410:
        //Bad request
        errorMessage = 'INVITE EXPIRED';
        break;
      case 404:
        break;
    }

    if(!errorMessage && !!error.errorCode) {
      errorMessage = error.errorCode;
    }

    return throwError(errorMessage);
  }

  private _handleCountryError(eventName, error: any) {
    let errorMessage: string;
    console['server'](eventName, error);

    switch (error.status) {
      case 400:
        errorMessage = '400 REQUEST';
        break;
      case 401:
        break;
      case 404:
        break;
    }
    return throwError(errorMessage);
  }

  validatePasswordLogic(applicantEmail: any, password: string): Promise<any> {
    let body = {
      "key": applicantEmail,
      "password": password
    };

    this.headerOptions = this._workflow.getHeaderOptions();
    return this._http
    .post(AppConfig.API_ENDPOINT() + '/api/v1/validate-password/validate-app-id', body, this.headerOptions)
    .pipe(
      map(async res => await this._extractData(res)),
      catchError((error: any) => {
        let errorMessage: string;

        switch (error.status) {
          case 400:
            errorMessage = 'INVALID_PASSWORD';
            break;
        }
        return throwError(errorMessage);
      })
    ).toPromise();
  }

  getUserApprovedFactorsForPasswordRecovery(systemId: string) {
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/authn/fetch-factors/' + systemId)
      .pipe(
        map((response) => response),
        map((response) => response),
        catchError((err) => err)
      );
  }
}
