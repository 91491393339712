import { Component, OnInit } from '@angular/core';
import { PreAuthService } from 'app/pre-auth/pre-auth.service';
import { LocalStorageService } from 'framework/localstorage.service';
import { Observable } from 'rxjs';
import {SharedService} from '../shared/services/shared.service';

declare var window: any;

@Component({
  selector: 'app-base-set-password',
  templateUrl: './base-set-password.component.html',
  styleUrls: ['./base-set-password.component.css']
})
export class BaseSetPasswordComponent implements OnInit {
  selectedPage = '';
  isAuthyCallback:boolean = false;
  isAuthyLoggedIn: boolean = false;

  constructor(private localStorage: LocalStorageService) { }

  ngOnInit(): void {
    this.isAuthyCallback = this.localStorage.getItem("isAuthyCallback");
    this.isAuthyLoggedIn = this.localStorage.getItem("isAuthyLoggedIn");

    this.selectedPage = 'KEYCLOAK';
    
  }
}
