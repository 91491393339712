import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
// import { tokenNotExpired } from '@auth0/angular-jwt';
import { map, catchError } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { SharedService } from 'app/shared/services/shared.service';
import { throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';

declare var window: any;

@Injectable()
export class AuthService {
  isLoggedIn: boolean = false;
  headerOptions: any;

  constructor(private _authHttp: HttpClient, private _router: Router, private localStorage: LocalStorageService,
    private _workflow: WorkflowService, private shareService: SharedService) {

     }


  updateAuthyRegisteredAndSession(inviteKey: string): Observable<Object> {

    let trackObj = {
      stage_name: 'authy',
      action_value: 'link verified'
    };
    window.track_event('update authy session to backend', trackObj, true, true);

    let body = {
      key:inviteKey 
    };

    let traces = {};
    traces['action'] = 'update-authy-session';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/update-session', body, this.headerOptions)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => {
          this.localStorage.setItem('authy_session',res);
          return res;
        }),
        catchError(err => this._handleError('authy update-session api failed', err))
        
      );
  }
  
  login(user: Object): Observable<Object> {
    let body = {
      "username": user['email'],
      "password": user['password'],
    };

    this.localStorage.setItem('user_email', user['email']);

    let traces = {};
    traces['action'] = 'SignIn';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/session', body, this.headerOptions)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => this._doAction(res)),
        catchError(err => this._handleError('session api failed', err))
      );
  }

  /**
   * added to call session v2 api
   * 
   * @param user 
   * @returns 
   */
  postLogin(user: Object): Observable<Object> {
    let body = {
      "username": user['email'],
      "password": user['password'],
    };

    body['key'] = this.localStorage.getItem('key'); 

    this.localStorage.setItem('user_email', user['email']);

    let traces = {};
    traces['action'] = 'SignIn';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v2/session/api/auth/session', body)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => this._doAction(res)),
        catchError(err => this._handleError('session api failed', err))
      );
  }

  getUserAuthEngine(emailId: string): Observable<Object> {
    let body = {
      "username": emailId
    };

    this.localStorage.setItem('user_email', emailId);

    let traces = {};
    traces['action'] = 'SignIn';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v2/session/api/auth/engine', body)
      .pipe(
        map((res) => this._extractData(res)),
        map((res) => this._doActionForForgotPasswordAuthEngine(res)),
        catchError(err => this._handleError('session api failed', err))
      );
  }

  checkLoggedIn(): boolean {
    // let status = (!!this.localStorage.getItem('access_token') &&
    //               tokenNotExpired(this.localStorage.getItem('access_token')) !== true);
    let status = (!!this.localStorage.getItem('access_token'));
    return status;
  }

  logout(): void {
    const sessionState = this.localStorage.getItem('session_state');
    if(!!sessionState) {
      this._authHttp.delete(AppConfig.API_ENDPOINT() + '/api/v1/session/' + this.localStorage.getItem('session_state')).subscribe(response => {
        console.log("Session is deleted.");
      }, error => {
        console.log("Error in logout of the session");
      });
    } else {
      console.log("Session state not found.");
    }
    
    this.isLoggedIn = false;
    // Setting language item after clear so it will reflect in forgot password..
    const existingLanguage = this.localStorage.getItem('language');
   
    this.shareService.customFormVariables = {};
    // Clear local storage
    this.localStorage.clear();
    this.localStorage.setItem('language', existingLanguage);
  }

  fetchSessionData(): Observable<object>{
    this.headerOptions = this.getHeaderOptions();

    return this._authHttp.get(AppConfig.API_ENDPOINT() + '/api/v1/authn/fetch-session-data' , this.headerOptions)
      .pipe(
        map(this._extractServerResponse),
        catchError(err => this._handleError('Fetch session data error.', err))
      );
  }

  getHeaderOptions(): any {
    const authHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Authorization': 'Bearer ' + this.localStorage.getItem('access_token'),
      'id' : this.localStorage.getItem('stateId')
    });

    const OPTIONS = { headers: authHeaders };

    return OPTIONS;
  }

  sendStateToServer(data: Object): Observable<Object> {
    let body: Object = {};
    let actionURL = data['action'];

    let dataFormation = {
      "current_stage": data["name"],
      "current_status": data["status"],
      "current_step": data["step"],
      "current_step_index": data["stepIndex"],
      "current_sub_step": data["subStep"],
      "current_sub_step_index": data["subStepIndex"],
      "current_iteration": data["iteration"],
    };

    /*
     * Sending data format changes as for Residential only otherwise it fails in the BE and hence
     * do not get appropriate Stage Step Details when user logs in back.
    */
    if (data['name'] === 'residential-data-v1') {
      body["address-data-list"] = [dataFormation];
    } else {
      body = dataFormation;
    }

    return this._authHttp.put(AppConfig.API_ENDPOINT() + actionURL, body)
      .pipe(
        map(this._extractServerResponse),
        map(this._doStateAction),
        catchError(err => this._handleError('send state to server failed', err))
      );
  }

  private _extractData(res: Object) {
    return (res) || {};
  }

  private _extractServerResponse(res: any) {
    return res;
  }

  private _doAction(response: Object) {
    if (!!response && !response['error']) {
      this.isLoggedIn = true;
        this.setKeycloakSessionProperties(response);
        this._workflow.getHeaderOptions();
        return response['id'];
    } else {
      return response;
    }
  }

  private _doActionForForgotPasswordAuthEngine(response: Object) {
    if (!!response && !response['error']) {
      if (!!response && !!response["additional_properties"]) {
        this.localStorage.setItem("custom_styles",response["additional_properties"]['custom_styles']);
        this.localStorage.setItem("auth_engine",response["additional_properties"]['auth_engine']);
      }
      
    }
    
    return response;
  }

  private setKeycloakSessionProperties(response) {
    this.localStorage.setItem('access_token', response['access_token']);
    this.localStorage.setItem('profile_id', response["attributes"]['profile_id'][0]);
    this.localStorage.setItem('session_timeout', response['session_timeout']);
    this.localStorage.setItem('invite_app_status', '');
    this.localStorage.setItem('session_id', response['id']);
    this.localStorage.setItem('session_state', response['session_state']);
    this.localStorage.setItem('secret_question_set', '' + response['secret_question_set']);
    console.log("session_state :", response['session_state']);
  }

  private _doStateAction(response: any) {
    return true;
  }

  private _handleError(eventName, error: any) {
    let errorMessage: string;
    let trackObj = {
      stage_name: 'login',
      action_value: 'error'
    };
    window.track_event(eventName, trackObj, true, true, true);

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
      case 405:
      case 403:
        //Bad request, Invalid Credentials - login invalid
        let _body = error|| {};
        errorMessage = _body['error']['error'] || 'Invalid Login';
        break;

      case 404:
        break;
      case 423:
        //errorMessage = error['error']['error'];
        errorMessage = 'ACCOUNT_LOCKED';
        break;
    }
    return throwError(errorMessage);
  }

}
