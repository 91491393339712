import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DifferentEmailComponent } from './../profile/different-email/different-email.component';
import { ForgotPasswordComponent } from './../profile/forgot-password/forgot-password.component';
import { UnsubscribeComponent } from './../profile/unsubscribe/unsubscribe.component';
import { SharedModule } from './../shared/shared.module';
import { CommonModule } from '@angular/common';
import { ProfileRoutingModule } from './profile-routing.module';
import { DisagreeTermsComponent, ExpiredInviteComponent, InvalidInvitationComponent, PrivateBrowsingComponent, UnsupportedBrowsingComponent } from '../pre-auth';
import { PasswordExpiredComponent } from 'app/pre-auth/password-expired/password-expired.component';
import { RedirectComponent } from 'app/redirect/redirect.component';
import { UnsupportedDeviceComponent } from 'app/pre-auth/unsupported-device/unsupported-device.component';
import { ThankyouComponent } from 'app/thankyou/thankyou.component';
import { QRCodeModule } from 'angularx-qrcode';
import {BaseForgotPasswordComponent} from './base-forgot-password/base-forgot-password.component';
@NgModule({
  imports: [
    FormsModule,
    SharedModule,
    CommonModule,
    ProfileRoutingModule,
    QRCodeModule
  ],
  declarations: [
    DifferentEmailComponent,
    ForgotPasswordComponent,
    BaseForgotPasswordComponent,
    UnsubscribeComponent,
    PasswordExpiredComponent,
    RedirectComponent,
    DisagreeTermsComponent,
    PrivateBrowsingComponent,
    UnsupportedBrowsingComponent,
    UnsupportedDeviceComponent,
    ThankyouComponent
  ],
  providers: []
})
export class ProfileModule {
}
