import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentUploadService } from './../document-upload.service';
import { BehaviorSubject } from 'rxjs';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from '../../../framework/i18n';
import { DocumentUploadConfig } from './../document-upload.config';
import { EducationConfig } from '../../education/education.config';
import { EmploymentConfig } from '../../employment/employment.config';
import { ProfessionalLicenseConfig } from '../../professional-license/professional-license.config';
import { MvrConfig } from '../../mvr/mvr.config';
import { CriminalConfig } from '../../criminal/criminal.config';
import { SharedService } from '../../shared/services/shared.service';
import { EventBusService } from 'framework/eventbus.service';
import { UtilService } from '../../../framework/utils/util.service';
import * as dateUtils from '../../../framework/utils/date.utils';

@Component({
  selector: 'document-category',
  templateUrl: './document-category.component.html',
  providers: [DocumentUploadService]
})

export class DocumentCategoryComponent implements OnInit {
  grpData = new BehaviorSubject<any>([]);

  @Input() grpIndex: number;
  @Input() inputIsIntlGID100P: boolean;
  @Input() inputIsIntlPRNCrim: boolean;
  @Input() inputIsIntlPRNAFP: boolean;
  @Input() inputIsIntlRegNzNzpv: boolean;
  @Input() isDynamicDocumentUpload: any;
  @Input() miDocument: any;

  @Input()
  set grpObj(value) {
    this.grpData.next(value);
  };

  get grpObj() {
    return this.grpData.getValue();
  }
  // @Input() inputAccordion: any;
  @Input() checkIs100PFeatureAvailable: boolean;
  @Input() checkAUCrim100PCalculationAVailable: boolean;
  @Input() checkIsAFP100PCalculationAvailable: boolean;
  @Input() inputIsFromEnhancedDashboard: boolean;
  @Output() documentOnUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentOnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentOnDownload: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentOnView: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentOnInstruction: EventEmitter<any> = new EventEmitter<any>();

  documentDataObj: any;
  groupDataObj: any;
  isUpload: boolean = false;
  accordionId: any;
  accordionTitle: string = '';
  isInviteEiaf: any;
  documnetSkippable: boolean = true;
  
  constructor(private _ts: TranslateService,
              private shareService: SharedService,
    private _eventBus: EventBusService,
    private _util: UtilService,
    private _documentService: DocumentUploadService) {
  }

  ngOnInit() {
    
    this._eventBus.dynamicDocumentActionClickedAnnounced$.subscribe(item => {
   	 if (!!item) {
       if (!!item['action']) {
         if (item['action'] === 'upload' && item['status'] === 'SUCCESS') {
          this.groupDataObj['isUpload'] = true;
          this.groupDataObj['groupIcon'] = true;
          this.miDocument['document']['profileDocId'] = item['profileDocumentId'];
          this.miDocument['document']['docBaseId'] = item['remoteDocId'];
          // this.miDocument['document'].isRemoteDocId = !! item['remoteDocId'] ? true : false;
          this.miDocument['document']['remoteFileName'] = item['remoteFileName'];
         } 
         else if (!!item && !!item['action'] && item['action'] === 'error') {
          this.groupDataObj['isUpload'] = true;
          this.groupDataObj['groupIcon'] = true;
         }
         else {
          this.groupDataObj['isUpload'] = false;
          this.groupDataObj['groupIcon'] = false;
          this.miDocument['document']['docBaseId'] = null;
          this.miDocument['document']['profileDocId'] = null;
          this.miDocument['document']['remoteFileName'] = null;
          
         }
       }
     }
    });

    if (this.isDynamicDocumentUpload) {
      this.groupDataObj = {};
      this.groupDataObj['groupIcon'] = false;
      this.groupDataObj['isUpload'] = false;
      if (!!this.miDocument && !!this.miDocument['status'] && this.miDocument['status'] === 'SUCCESS' ) {
        this.groupDataObj['isUpload'] = true;
        this.groupDataObj['groupIcon'] = true;
      }
      
      return;
    }

    this.mandatoryDocumentBanner();
    
    // Check Invite is Eiaf
    this.isInviteEiaf = this.shareService.checkInviteIsEIAF();
    this.grpData
      .subscribe(res => {
        let groupIcon = false;
        this.groupDataObj = res;

        for (let i in this.groupDataObj['statewide-criminal-data-list']) {
          if (this.groupDataObj['statewide-criminal-data-list'][i].hasOwnProperty('status')) {
            let status = this.groupDataObj['statewide-criminal-data-list'][i]['status'];
            if (this.getSuccessCondition(status)) {
              this.groupDataObj['statewide-criminal-data-list'][i]['isUpload'] = true;
              groupIcon = true;
            }

            if (this.getDisableConditions(this.groupDataObj['statewide-criminal-data-list'][i])) {
              this.groupDataObj['statewide-criminal-data-list'][i]['isFilledFormDisabled'] = true;
            }
          } else {
            this.groupDataObj['statewide-criminal-data-list'][i]['isUpload'] = false;
          }
        }

        this.groupDataObj['groupIcon'] = groupIcon;
      });
  }

  getSuccessCondition(condition): boolean {
    if (condition.toUpperCase() === 'SUCCESS') {
      return true;
    } else {
      return false;
    }
  }

  getDisableConditions(condition): boolean {
    if (condition.hasOwnProperty('cpic_alreadyUploaded')) {
      if (condition['cpic_alreadyUploaded'].toUpperCase() === 'TRUE') {
        return true;
      } else {
        return false;
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.accordionId = Math.floor(this._util.getCryptoRandom() * 10000);
      this.accordionHeader();
    }, 200);
  }

  /* -------------------------- Main Page Methods - STARTS --------------------------------- */

  onDownloadDocumentClick(id, grpIndex, categoryIndex, remoteId, isRemoteDocId) {
    if (isRemoteDocId !== false) {
      let actionType = 'download';
      this.documentOnDownload.emit({ id, grpIndex, categoryIndex, actionType, remoteId });
    }
  }

  onUploadDocumentClick(id, grpIndex, categoryIndex, docTypeId, profileDocID?) {
    
    let actionType = 'upload';
    this.documentOnUpload.emit({ id, grpIndex, categoryIndex, actionType, docTypeId, profileDocID });
 
  }

  onDeleteDocumentClick(id, grpIndex, categoryIndex, profileDocID?) {
    let actionType = 'delete';
    this.documentOnDelete.emit({ id, grpIndex, categoryIndex, actionType, profileDocID });
  }

  onViewDocumentClick(profileDocId) {
    this.documentOnView.emit(profileDocId);
  }

  onInstructionClick(id, grpIndex, categoryIndex, instructionId, isInstruction) {
    if (isInstruction !== false) {
      let actionType = 'instruction';
      this.documentOnInstruction.emit({ id, grpIndex, categoryIndex, actionType, instructionId });
    }
  }

  accordionHeader() {
    if (this.isDynamicDocumentUpload) {
      return;
    }
    let country = '';
    let groupDescription = '';
    let fileDescription = '';
    let title = '';
    let groupName = this.groupDataObj['group-name'];

    if (this.groupDataObj['group-desc'] === undefined) {
      fileDescription = this.titleCase(this.groupDataObj['statewide-criminal-data-list'][0]['file_desc']);

      this.accordionTitle = fileDescription;
    } else {
      country = this.capitalizeWord(this.groupDataObj['country']);
      groupDescription = this.titleCase(this.groupDataObj['group-desc']);
      title = this.formatGroupDescription(groupDescription, country);

      this.accordionTitle = title;
    }

    if (!!groupName) {
      this.accordionTitle += " : " + groupName;
    }
  }

  titleCase(str) {
    if (str !== undefined && str !== '') {
      // let duplicateStr = '';
      // let index = 0;

      if (str.indexOf('.Optional') > -1) {
        str = str.replace('.Optional', '');
      } else if (str.indexOf(':Optional') > -1) {
        str = str.replace(':Optional', '');
      }

      return str;
    } else {
      return '';
    }
  }

  isFirstCharacterAlphabet(ch) {
    return /^[A-Za-z]{1,1}$/.test(ch);
  }

  capitalizeWord(str) {
    if (str !== undefined && str !== '') {
      let splitStr = str.toLowerCase().split(' ');

      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        let testCharacter = this.isFirstCharacterAlphabet(splitStr[i].charAt(0).toUpperCase());
        if (testCharacter)
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        else
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].charAt(1).toUpperCase() + splitStr[i].substring(2);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    } else {
      return '';
    }
  }
  /**
   * Format and translate the group header of upload document
   * @param groupDescription
   * @param country
   */
  formatGroupDescription(groupDescription, country) {
    let title: string = '';

    if (groupDescription) {
      if (groupDescription.indexOf(';') > -1) {
        groupDescription = groupDescription.replace(';', '');
      }
      
      // For multilingual support..
      if (groupDescription.indexOf('||') > -1) {
        // New method for skipped docs in enhanced dashboard
        if (this.inputIsFromEnhancedDashboard) {
          groupDescription = this.formatSkippedDocumentsTitle(groupDescription);
        } else {
          // Existing method
          let splitStr = groupDescription.split('||');
          groupDescription = this._ts.translate(splitStr[0]) + " " + this.getTraslationFromEducationTypes(splitStr[1]);
        }
      } else {
        groupDescription = this._ts.translate(groupDescription);
      }
      
      // Concat country name if available
      if (country) {
        if (this.inputIsFromEnhancedDashboard) {
          title = country + ': ' + groupDescription;
        } else {
          // Existing code
          if (groupDescription.trim().length > 0) {
            let index = -1;
            if (groupDescription.includes("：")) {
                index = groupDescription.indexOf('：');
            } else if (groupDescription.includes(":")) {
                index = groupDescription.indexOf(':');
            }
            title = (index > -1) ? this.formGroupTitle(index, groupDescription, country) : groupDescription + "; " + country;
          }
        }
      } else {
        title = groupDescription;
      }
    }
    
    return title;
  }

  // Form group title
  formGroupTitle(index, groupDescription, country) {
    let groupTitle = groupDescription.slice(0, index).trim();
    groupTitle = groupTitle + ' : ' + country;
    return groupTitle;
  }

  /**
   * translate education / employment / license / mvr/ criminal types
   * if any exist in document group header
   *
   * @param key
   */
  getTranslationContent(key: string) {
    if (!!EducationConfig.getEducationType(key)) {
      return EducationConfig.getEducationType(key);
    } else if (!!EmploymentConfig.getEmploymentCategory(key)) {
      return EmploymentConfig.getEmploymentCategory(key);
    } else if (!!ProfessionalLicenseConfig.getLicenseType(key)) {
      return ProfessionalLicenseConfig.getLicenseType(key);
    } else if (!!MvrConfig.getMvrType(key)) {
      return MvrConfig.getMvrType(key);
    } else if (!!CriminalConfig.getType(key)) {
      return CriminalConfig.getType(key);
    } else {
      return key;
    }
   }

  getTraslationFromEducationTypes(key: string) {
    if (!!key) {
      let educationTypesList = this._util.getArrayFromJsonString(this._ts.translate(EducationConfig.EDUCATION_TYPES));
      for (let educationType of educationTypesList) {
        if (educationType['id'].toUpperCase() === key.toUpperCase()) {
          return educationType['name'];
        }
      }
      return key;
    }
    return '';
  }

  mandatoryDocumentBanner(){
    let value = this._documentService.documentSkipSubmissionPreferences();
    if(value['no_of_days_future_submission'] == 0){
      this.documnetSkippable = false;
    }
  }
  
  formatSkippedDocumentsTitle(groupDescription) {
    // groupDescription = "IIT Kharagpur:Masters||2010||2014"; // Accenture||2010-06||2014-10
    let splitData = groupDescription.split('||');
    let finalTitle = '';
    
    if (splitData[0].indexOf(':') > -1) {
      let value = splitData[0].split(':');
      finalTitle = value[0] + ": " + this.getTraslationFromEducationTypes(value[1]);
    } else {
      finalTitle = splitData[0];
    }
    
    if (!!splitData[1]) {
      finalTitle = finalTitle + ': ' + this.convertDateToMonthAndYear(splitData[1]);
    }
    
    if (!!splitData[2]) {
      finalTitle = finalTitle + ' - ' + this.convertDateToMonthAndYear(splitData[2]);      
    }
    
    return finalTitle;
  }
  
  convertDateToMonthAndYear(date) {
    const monthNames: any = this._ts.instant('MONTH_NAMES');
    let finalDate = date;
    
    if (date.indexOf('-') > -1) {      
      let startDate = dateUtils.getDateFormat(date, monthNames);
      finalDate = startDate;
    }
    
    return finalDate;
  } 
  
  /* -------------------------- Main Page Methods - ENDS --------------------------------- */
}
