import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';
import { map, catchError } from 'rxjs/operators';
import { LocalStorageService } from '../../framework/localstorage.service';
import { throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ClarityService } from 'app/clarity.service';

declare var window: any;
@Injectable({
  providedIn: 'root'
})
export class PreAuthService {
  isValidToken: Object = {};
  private trackObj: Object;
  constructor(private _http: HttpClient, private _router: Router, private localStorage: LocalStorageService,
    private _clarityService: ClarityService) { }

  validateToken(token: string): Observable<any> {
    /*
     * Need to make the HTTP GET API call with Token and will return
     * 410 - expired - > Login
     * 200 - > Set Password
    */

    this.trackObj = {
      stage_name: 'pre auth',
      action_value: 'invite link clicked'
    };

    window.track_event('validate token', this.trackObj, true, true);
    return this._http
      .get(AppConfig.API_ENDPOINT() + `/api/web/invite/?key=${token}` + '&d=' + (new Date()).getTime())
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err)));
  }

  getInviteAuthEngine(key: string): Observable<any> {
    this.trackObj = {
      stage_name: 'pre auth',
      action_value: 'get auth engine'
    };

    return this._http
      .get(AppConfig.API_ENDPOINT() + `/api/web/invite/auth-engine/?key=${key}` + '&d=' + (new Date()).getTime())
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => this._handleError(err)));
  }

  getAuthEngineByEmail(email: string): Observable<any> {
    this.trackObj = {
      stage_name: 'pre auth',
      action_value: 'get auth engine'
    };
    let body = {
      "email": email
    }
    return this._http
      .post(AppConfig.API_ENDPOINT() + `/api/web/invite/auth-engine`, body)
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => this._handleError(err)));
  }

  private _extractData(res: any) {
    return (res) || {};
  }

  private _doAction(response: Object) {
    this.localStorage.setItem('inviteType', response['type']);
    this.localStorage.setItem('subType', response['subType']);
    this.localStorage.setItem('key', response['key']);
    this.localStorage.setItem('instance', response['instance']);
    this.localStorage.setItem('inviteId', response['id']);
    this.localStorage.setItem('invite_id', response['id']);
    this.localStorage.setItem('profile_id', response['profile_id']);
    this.localStorage.setItem('system_id', response['system_id']);
    this.localStorage.setItem('applicant_email', response['applicant']['email']);
    this.localStorage.setItem('first_name', response['applicant']['first_name']);
    this.localStorage.setItem('last_name', response['applicant']['last_name']);
    this.localStorage.setItem('company_name', response['account']['name']);
    this.localStorage.setItem('is_report_on', response['account']['is_report_on']);
    this.localStorage.setItem('account_id', response['account']['id']);
    this.localStorage.setItem('middle_name', response['applicant']['middle_initial']);
    this.localStorage.setItem('wps_number', response['applicant']['provider_ref_number']);
    this.localStorage.setItem('is_gender_mandatory', response['applicant']['is_gender_mandatory']);
    this.localStorage.setItem('invite_app_status', '');
    this.localStorage.setItem('da_url', response['da_url']);
    this.localStorage.setItem('applicant_status', response['applicant']['status']);
    this.localStorage.setItem('f_name', response['applicant']['first_name']);
    this.localStorage.setItem('rid_status', response['account']['rid_status']);
    this.localStorage.setItem('place_search_api', response['account']['place_search_api']);
    this.localStorage.setItem('SMS_OPTOUT_ENABLED', response['account']['SMS_OPTOUT_ENABLED']);
    this.localStorage.setItem('iso_country_code', response['identity_current_residence_country']);
    this.localStorage.setItem('lock_current_country', response['lock_current_residence_country']);
    

    this.localStorage.setItem('import_data_status', response['account']['import_data_status']);
    this.localStorage.setItem('import_data_result', response['account']['import_data_result']);
    if (!!response["package"]) {
      this.localStorage.setItem('package_name', response["package"]['name']);
      this.localStorage.setItem('package_components', response["package"]['distinct_components']);
      if (!!response["package"]["scheme"]) {
        this.localStorage.setItem('scheme', response["package"]["scheme"]);
      }
    }

    this.localStorage.setItem('is_international', response["account"]["is_intl"]);

    if(response['applicant'] !== undefined &&  response['applicant']['data'] !== undefined
        &&  response['applicant']['data']['APPLICANT_STATUS'] !== undefined){
          this.localStorage.setItem('review_status', response['applicant']['data']['APPLICANT_STATUS']);
    }

    if (response['applicant']['middle_initial'] === undefined) {
      this.localStorage.setItem('m_name', '');
    } else {
      this.localStorage.setItem('m_name', response['applicant']['middle_initial']);
    }

    this.localStorage.setItem('l_name', response['applicant']['last_name']);
    this.localStorage.setItem('pa_dot_direct_preference', response['account']['pa_dot_direct_preference']);
    this.localStorage.setItem('pa_dot_drug_order_preference', response['account']['pa_dot_drug_order_preference']);

    if (response['account']['pa_dot_limit_employment_verification']) {
      this.localStorage.setItem('pa_dot_limit_employment_verification', response['account']['pa_dot_limit_employment_verification']);
    }

    if (response['account']['reason_for_leaving_mandatory']) {
      this.localStorage.setItem('reason_for_leaving_mandatory', response['account']['reason_for_leaving_mandatory']);
    }

    if (response['account']['ea_account_bg_report_completion_eta']) {
      this.localStorage.setItem('ea_account_bg_report_completion_eta', response['account']['ea_account_bg_report_completion_eta']);
    }

    if (response['account']['pa_lock_pre_population_for_custom_form_fields']) {
      this.localStorage.setItem('pa_lock_pre_population_for_custom_form_fields', response['account']['pa_lock_pre_population_for_custom_form_fields']);
    }

    if (response['account']['is_ea_rightId']) {
      this.localStorage.setItem('is_ea_rightId', response['account']['is_ea_rightId']);
    }

    // Set filter eduction type preference
    if (response['account']['pa_filter_education_types']) {
      this.localStorage.setItem('pa_filter_education_types', response['account']['pa_filter_education_types']);
    }

    if (response['account']['is_candidate_age_based_scope']) {
      this.localStorage.setItem('is_candidate_age_based_scope', response['account']['is_candidate_age_based_scope']);
    }

    if (!!response['current_address_country']) {
      this.localStorage.setItem('current_address_country', response['current_address_country']);
    }

    if (!!response['is_country_based_consent']) {
      this.localStorage.setItem('is_country_based_consent', response['is_country_based_consent']);
    }
    
    if (!!response['disable_tos']) {
      this.localStorage.setItem('disable_tos', true);
    }

    if (response['account']['country_of_residence_pref_value']) {
      this.localStorage.setItem('country_of_residence_pref_value', response['account']['country_of_residence_pref_value']);
    }

    if (response['account']['pa_lock_down_name']) {
      this.localStorage.setItem('pa_lock_down_name', response['account']['pa_lock_down_name']);
    }
    if (response['account']['pa_do_not_override_name']) {
      this.localStorage.setItem('pa_do_not_override_name', response['account']['pa_do_not_override_name']);
    }

    if (response['account']['user_alert_on_error']) {
      this.localStorage.setItem('user_alert_on_error', response['account']['user_alert_on_error']);
    }

    if (response['account']['use_font_based_signature']) {
      this.localStorage.setItem('use_font_based_signature', response['account']['use_font_based_signature']);
    }

    if (response['account']['SIGNATURE_FONT']) {
      this.localStorage.setItem('SIGNATURE_FONT', response['account']['SIGNATURE_FONT']);
    }

    if (response['account']['enable_customer_feedback_survey']) {
      this.localStorage.setItem('enable_customer_feedback_survey', response['account']['enable_customer_feedback_survey']);
    }

    if (!!response['account'] && !!response['account']['enable_mfa'] && response['account']['enable_mfa'].toUpperCase()) {
      this.localStorage.setItem('pa_enable_mfa', response['account']['enable_mfa']);

      if (response['account']['pa_user_auth_model']) {
        this.localStorage.setItem('user_auth_model', response['account']['pa_user_auth_model']);
      }
    }

    if (!!response['is_survey_captured']) {
      this.localStorage.setItem('is_survey_captured', response['is_survey_captured']);
    }

    this.localStorage.setItem("selected_invite_notification", response['notification']);

    if(!!response && !!response['type'] && response['type'].toUpperCase()==='CONSENT' && !!response['account'] && !!(response['account']['setting_form_enable_electronic_signature_only'])) {
      this.localStorage.setItem('setting_form_enable_electronic_signature_only', response['account']['setting_form_enable_electronic_signature_only']);
    }

    if (!!response && !!response['custom_styles']) {
      this.localStorage.setItem('custom_styles', response['custom_styles']);
    }

    if (!!response && !!response['language']) {
      this.localStorage.setItem('language', response['language']);
    }

    if (!!response && !!response['language_country_code']) {
      this.localStorage.setItem('language_country_code', response['language_country_code']);
    }

    if (!!response && response['account']['is_salary_enabled']) {
      this.localStorage.setItem('is_salary_enabled', response['account']['is_salary_enabled']);
    }

    if (!!response && !!response['account'] && !!response['account']['support_chat_experience']) {
      this.localStorage.setItem('support_chat_experience', response['account']['support_chat_experience']);
    }
    
    if (!!response && !!response['account'] && !!response['account']['enhanced_dashboard']) {
      this.localStorage.setItem('enhanced_dashboard', response['account']['enhanced_dashboard']);
    }

    if (!!response && !!response['account'] && !!response['account']['credit_card_pay_enabled']) {
      this.localStorage.setItem('credit_card_pay_enabled', response['account']['credit_card_pay_enabled']);
    }
    if (!!response && !!response['account'] && !!response['account']['payment_partner_pref_value']) {
      this.localStorage.setItem('payment_partner_pref_value', response['account']['payment_partner_pref_value']);
    }

    if (!!response && !!response['applicant'] && !!response['applicant']['pending_info_due_date']) {
      this.localStorage.setItem('pending_info_due_date', response['applicant']['pending_info_due_date']);
    }

    if (!!response && !!response['applicant'] && !!response['applicant']['id']) {
      this.localStorage.setItem('applicant_id', response['applicant']['id']);
    }
    
    if (!!response && !!response['account'] && !!response['account']['azure_cognitive_search_enabled']) {
      this.localStorage.setItem('azure_cognitive_search_enabled', response['account']['azure_cognitive_search_enabled']);
    }
    
    if (!!response && !!response['account'] && response['account']['lookup_service_url']) {
      this.localStorage.setItem('lookup_service_url', response['account']['lookup_service_url']);
    }

    if (!!response && !!response['account'] && response['account']['show_biometric_consent']) {
      this.localStorage.setItem('show_biometric_consent', response['account']['show_biometric_consent']);
    }
    
    if (!!response && !!response['account'] && !!response['account']['accent_character_translation_enabled']) {
      this.localStorage.setItem('accent_character_translation_enabled', response['account']['accent_character_translation_enabled']);
    }

    if (!!response && !!response['account'] && response['account']['Switch_url']) {
      this.localStorage.setItem('switch_url', response['account']['Switch_url']);
    }
    if (!!response && !!response['account'] && response['account']['enable_yoti_with_switch']) {
      this.localStorage.setItem('enable_yoti_with_switch', response['account']['enable_yoti_with_switch']);
    }    
    
    if (response['show_language_selection'] !== undefined) {
      this.localStorage.setItem('show_language_selection', response['show_language_selection']);
    }
    
    if (!!response && !!response['account'] && !!response['account']['rid_retry_count']) {
      this.localStorage.setItem('rid_retry_count', parseInt(response['account']['rid_retry_count']));
    }

    if (!!response && !!response['account'] && response['account']['esignature_min_size']) {
      this.localStorage.setItem('esignature_min_size', parseInt(response['account']['esignature_min_size']));
    }

    this.localStorage.setItem('visiter_id', uuidv4());

    this._clarityService.bootUser(response);

    return response;
  }

  private _handleError(error: any) {
    let _body = error || {};
    let language: string = (_body['error']['language']) ? (_body['error']['language']).toLowerCase() : undefined;
    let languageCountryCode: string = (_body['error']['language_country_code']) ? (_body['error']['language_country_code']).toLowerCase() : undefined;
    let errorMessage: string;
    let errorCode: any;
    let deleteMsg: string = (_body['error']['error']);
    let isInviteDeleted: boolean = false;
    let inviteType: string;
    if (deleteMsg !== null && deleteMsg !== undefined && deleteMsg != "") {
      if (deleteMsg.indexOf("deleted") != -1) {
        isInviteDeleted = true;
      }
    }

    if (this.localStorage != null && this.localStorage != undefined) {
      this.localStorage.setItem('inviteStatus_400', error.headers.get('location'));
    }
    inviteType = !!error['error'] ? error['error']['type'] : '';
    errorCode = error.status;

    switch (error.status) {
      case 400:
        errorMessage = 'INVALID';
        break;
      case 401:
        errorMessage = 'INVALID';
        break;
      case 410:
        // Bad request, Invalid Credentials - login invalid
        errorMessage = 'EXPIRED';
        break;
      case 404:
        errorMessage = 'INVALID';
        break;
    }

    if (isInviteDeleted) {
      errorMessage = 'DELETED';
    }
    // return throwError(errorMessage);
    return throwError({
      'language': language,
      'languageCountryCode': languageCountryCode,
      'errorMessage': errorMessage,
      "errorCode": errorCode,
      "inviteType": inviteType
    });
  }

  getAccountInfo(inviteType: string, accountId: string, accountTag: string, inviteSource: string, userPrefCountryCode: string, userPrefLanguageCode: string): Observable<any> {
    return this._http
                 .get(AppConfig.API_ENDPOINT() + `/api/web/account/${inviteType}/${accountId}/${accountTag}` + '?source=' + `${inviteSource}` + '&country_cd=' + userPrefCountryCode + '&language_cd=' + userPrefLanguageCode)
                 .pipe(
                   map(res => this._extractData(res)),
                   map(res => this.doAction(res)),
                   catchError(this._handleError));
  }

  private doAction(response: Object) {
    console.log("identity-pre-check.service.ts response ", response);
    return response;
  }
}
