
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { PlaceHolderFloat } from 'framework/form/placeholder.directive';
import { ErrorInfoComponent } from 'framework/form/errorInfo.directive';
import { LocalStorageService } from 'framework/localstorage.service';
import { ProfileService } from '../profile.service';
import { SharedService } from '../../shared/services/shared.service';
import { AppConstants } from 'app/app.constants';
import { PreAuthService } from 'app/pre-auth/pre-auth.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'app/login';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'environments/environment';

declare var $: any;
declare var window: any;

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    providers: [PlaceHolderFloat, ProfileService, ErrorInfoComponent]
})

export class ForgotPasswordComponent implements OnInit {
    body: Object;
    isInvalidEmail = false;
    email: string;
    isEmailError: boolean = false;
    isEmailErrorInfo: boolean = false;
    emailErrorMessage: string = '';
    isEmailValid: boolean = false;
    isValid: boolean;
    isResend: boolean = false;
    isInviteEiaf: boolean = false;

    constructor(private _router: Router,
        private _placeholder: PlaceHolderFloat,
        private _localStorage: LocalStorageService,
        private _profileService: ProfileService,
        private _shareService: SharedService,
        private _errorInfo: ErrorInfoComponent,
        private _authService: AuthService,
        private _preAuthService: PreAuthService,
        @Inject(DOCUMENT) private document: any,
        @Inject(MsalService) private _msalService: MsalService,) { }

    ngOnInit() {
        this._placeholder.placeholderFloatFunc();
        this.email = this._localStorage.getItem('applicant_email');
        this.isInviteEiaf = this._shareService.checkInviteIsEIAF();

        if (!!this.email) {
          this.enableButton(this.email);
        }
        
        this._errorInfo.errorInfo();
    }

    standardForgotPassword(emailID) {
      $('#plsWait-modal').modal('show');
      
      let trackObj = {
        stage_name: 'forgot-password',
        action_value: 'clicked'
      };

      this._profileService.forgotPassword(emailID).subscribe(response => {
          $('#plsWait-modal').modal('hide');
          $('#forgotPassword-modal').modal('show');
          this.isResend = true;
          
          window.track_event('forgot password successful', trackObj, true, true);
      }, error => {
          $('#plsWait-modal').modal('hide');
          this.isInvalidEmail = true;
          
          window.track_event('forgot password failed', trackObj, true, true);
      });
    }

    forgotPwdLogin(value: Object, valid: boolean): void {
      let profileId = this._localStorage.getItem('profile_id');
      const emailID = value['email'];
      this._localStorage.setItem('applicant_email', emailID);

      let trackObj = {
        stage_name: 'forgot-password',
        action_value: 'clicked'
      };

      window.track_event('forgot password attempt', trackObj, true, true);
      
      let inviteKey = this._localStorage.getItem('invite_key');

      // Checking if application is loaded with invite key or accessed via base url
      if (!inviteKey) {
        $('#oidc_plsWaitmodal').modal('show');
        // Call GET of invite auth engine, if AUTHY engine then redirect to authy reset password screen, else existing flow
        this._preAuthService.getAuthEngineByEmail(emailID).subscribe(response => {
          let isAuthyEnabled = response["auth_engine"];
          
          if (isAuthyEnabled === 'AUTHY') {
            window.track_event('invite auth engine - authy', trackObj, true, true);
            
            if(!!response['key']) {
              this._localStorage.setItem("invite_key", response['key']); //without key fetch-authy-token fails
              this._router.navigate(['/authy/authentication']);
              return;
            }  else {
              this._msalService.loginRedirect({
                authority: environment.policies.authorities.resetPassword.authority,
                scopes: environment.policies.scopes,
              });
            }

          } else {
            window.track_event('invite auth engine - kc', trackObj, true, true);
            $('#oidc_plsWaitmodal').modal('hide');
            this.standardForgotPassword(emailID);
          }
        }, error => {
          $('#oidc_plsWaitmodal').modal('hide');
          this.isEmailError = true;
          this.isEmailErrorInfo = true;
          this.isEmailValid = false;
          this.emailErrorMessage = 'LOGIN_EMAIL_ERROR_MSG';
        });
      } else {
        window.track_event('user auth engine - keycloak', trackObj, true, true);
        this.standardForgotPassword(emailID);
      }
    }

    redirectDiffEmail(): void {
      this._next('./diff-email');
    }

    private _next(url): void {
        this._router.navigate([url]);
    }

    validateEmail(value: string) {
        let inputEmail = value;
        let regexVal = AppConstants.EMAIL_REGEX;
        let emailRegex = new RegExp(regexVal);

        if (inputEmail == "") {
            this.isEmailError = true;
            this.isEmailErrorInfo = true;
            this.isEmailValid = false;
            this.emailErrorMessage = 'LOGIN_EMAIL_ERROR_MSG';
        }
        else if (!!inputEmail && !emailRegex.test(inputEmail)) {
            this.isEmailError = true;
            this.isEmailErrorInfo = true;
            this.isEmailValid = false;
            this.emailErrorMessage = 'FORGOT_EMAIL_FORMAT_ERROR_MSG';
        }
        else {
            this.isEmailError = false;
            this.isEmailErrorInfo = false;
            this.emailErrorMessage = '';
            this.isEmailValid = true;
        }
    }

    enableButton(value: string) {
        let regexVal = AppConstants.EMAIL_REGEX;
        let regex = new RegExp(regexVal);

        if (regex.test(value)) {
            this.isValid = true;
        } else {
            this.isValid = false;
        }
    }

    closeEmailError() {
        this.isEmailError = false;
    }

    closeInvalidEmailError() {
        this.isInvalidEmail = false;
    }

    modalClose() {
        $('#forgotPassword-modal').modal('hide');
    }

}
