import { Injectable } from '@angular/core';
import { SharedService } from 'app/shared/services/shared.service';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class TrackEventService {
  constructor(private sharedService: SharedService) {
  }

  updateTrackEvent(name, metaData, isBehavioralMetrics: boolean, isOperationalMetrics: boolean) {
    // Code modified to send generic stage name + event value as event name because
    // unique event names count was going above 999
    // Ex: If event name was 'United States is the selected country', then for every country
    // new event name is generated. Same holds true for Address, Institution, Address Details, State etc
    // New format is applicant info : next clicked
    let eventName, payload;

    eventName = { stage_name : metaData.stage_name, action_value: metaData.action_value};
    payload = { action: name };

    // isBehavioralMetrics (For Clarity), isOperationalMetrics (For Log audits)
    if (isOperationalMetrics) {
      this.sharedService.sendEventsToAudits(name, eventName).subscribe(response => {
        console.log("Events sent to audits successfuly.....");
      }, error => {
        console.log("ERROR : Failed to send events to audits");
      });
    }

    if (isBehavioralMetrics)
      window.track_truid_event(name, metaData);
  }

  processTrackEvent(eventName, stageName, startTime, metaData, isBehavioralMetrics, isOperationalMetrics) {
    let endTime = new Date().getTime();
    let timeDiff = (endTime - startTime) / 1000;
    timeDiff = Math.floor(timeDiff);
    let trackObj = {
      stage_name: stageName,
      time_spent: timeDiff
    };
    if (metaData) {
      Object.assign(trackObj, metaData);
    }
    // Update Log audits
    this.updateTrackEvent(eventName, trackObj, isBehavioralMetrics, isOperationalMetrics);
  }
}
